import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';

import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedinIn, FaInstagram, FaVimeoV, FaDribbble, FaBehance } from "react-icons/fa";
import Tilty from 'react-tilty';
import axios from "axios";




const Keynotes = () => {
    const [keyNotesList, setkeynotesData] = useState(null);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleAbstract = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };
    useEffect(() => {
        const fetchKeynotesData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}api/speakers/getbyType`,{
                  type: "Keynote Speaker",
                });
                setkeynotesData(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching program data:', error);
            }
        };
  
        fetchKeynotesData();
    }, []);
    if (!keyNotesList) {
      return <div>Loading...</div>; // Ou tout autre indicateur de chargement
    }
   
    return (
        <>
        <SEO title="Plenary keynotes" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
          
             <BreadCrumbOne 
                title="Plenary keynotes"
                page="Programme"
                />
       <div className="section-padding-equal team-details-area">
  {keyNotesList && keyNotesList.map((keynote, index) => (
    <div className="container" key={index}>
      <div className="row align-items-center" style={{  borderBottom: index !== keyNotesList.length - 1 ? '1px solid' : 'none',borderBottomColor: '#549C9C',paddingBottom: '25px'}} >
        {/* Condition pour inverser l'affichage selon l'index */}
        {index % 2 === 0 ? (
          <>
         
            <div className={`col-lg-4 ${index % 2 !== 0 ? 'order-lg-2' : ''} order-1`}>
              <div className="team-details-thumb">
                <img  src={process.env.REACT_APP_API_URL + keynote.image} alt={keynote.first_name} />
              </div>
              <div className="team-details-content">
                <h6 className='title' style={{ color: '#549C9C' }} ><strong>{keynote.first_name} {keynote.last_name} </strong></h6>
                
              </div>
            </div>
            <div className={`col-lg-8 ${index % 2 !== 0 ? 'order-lg-1' : ''} order-2`}>
            <div className="team-details-content">
            <p style={{textAlign: 'justify',fontStyle:'italic', textAlign:'left'}}>{keynote.bio}</p>
            <span className="subtitle">{keynote.title_ppt}</span>
          {keynote.abstract &&  <> <p style={{ textAlign: 'justify', fontStyle: 'italic', textAlign: 'left' }}>
                                                    {expandedIndex === index || !keynote.abstract || keynote.abstract.length < 500 ? keynote.abstract : `${keynote.abstract.slice(0, 500)}...`}
                                                </p>
                                                {keynote.abstract && keynote.abstract.length > 500 && (
                                                    <button onClick={() => toggleAbstract(index)} className="axil-btn btn-fill-primary" style={{  padding: "20px 20px", fontSize: "15px", width: "150px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        {expandedIndex === index ? "Read less" : "Read more"}
                                                    </button>
                                                )} </>}
          </div>
                
              
            </div>
            
          </>
        ) : (
         <>
            <div className={`col-lg-8 ${index % 2 !== 0 ? 'order-lg-1' : ''} order-2`}>
            <div className="team-details-content">
            <p style={{textAlign: 'justify' ,fontStyle:'italic', textAlign:'left'}}>{keynote.bio}</p>
            <span className="subtitle">{keynote.title_ppt}</span>
            {keynote.abstract &&  <> <p style={{ textAlign: 'justify', fontStyle: 'italic', textAlign: 'left' }}>
                                                    {expandedIndex === index || !keynote.abstract || keynote.abstract.length < 500 ? keynote.abstract : `${keynote.abstract.slice(0, 500)}...`}
                                                </p>
                                                {keynote.abstract && keynote.abstract.length > 500 && (
                                                    <button onClick={() => toggleAbstract(index)} className="axil-btn btn-fill-primary" style={{  padding: "20px 20px", fontSize: "15px", width: "150px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        {expandedIndex === index ? "Read less" : "Read more"}
                                                    </button>
                                                )} </>}
          </div>
                
              
            </div>
            <div className={`col-lg-4 ${index % 2 !== 0 ? 'order-lg-2' : ''} order-1`}>
              <div className="team-details-thumb">
                <img src={process.env.REACT_APP_API_URL + keynote.image} alt={keynote.first_name} />
              </div>
              <div className="team-details-content">
                <h6 className='title' align="center" style={{ color: '#549C9C' }}><strong>{keynote.first_name} {keynote.last_name}</strong></h6>
    
              </div>
            </div>

        </>
        )}
      </div>
      
    </div>
  ))}
</div>


            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Keynotes;