import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FormOne from '../contact/FormOne';
import VideoModal from './VideoModal';


const BannerFive = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    return (
        <div className="banner banner-style-5" style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/images/cdio/banner-tunisia.jpg"})`}}>
            <div className="container">
                <div className="row">
                <div className="col-lg-3 col-xl-3"></div>
                    <div className="col-lg-5 col-xl-5">
                        <div className="banner-content">
                            <h1 className="title" style={{textAlign :"center"}} >TUNISIA 
                              </h1>
                              <h3 className="title-2" style={{textAlign :"center" }}>The Jewel of Africa 
                              </h3>
                            <div className="title" style={{textAlign :"center"}}>
                            <a href="#" onClick={handleOpenModal}>
                            <img  style={{maxWidth : "70%" }} src={process.env.PUBLIC_URL + "/images/cdio/play.png"} alt="play-icon" />
</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3"></div>
                </div>
            </div>
            <VideoModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
    )
}

export default BannerFive;