import React from 'react';
import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import CaseCallForPaper from '../../component/casestudy/CaseCallForPaper';
import SectionTitle from '../../elements/section-title/SectionTitle';
import SplashBanner from '../../component/banner/SplashBanner';


const SocialEvents = () => {


    return (
        <>
        <SEO title="Social events" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Social events"
                page="Programme"
                />
       <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                      
                        <div className="col-lg-12">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    {/* <h3 className="title">We delivered</h3> */}
                                    <p>Did you know that Tunisia has been one of the major centers of Mediterranean culture for 3,000 years? The heritage of ancient Carthage combines history, sensations, aromas and flavors to amaze you. From the souks to the beaches, monuments and fine dining, everything here is traditional, relaxed, harmonious and classy.</p>
                                </div>
                              

                            </div>
                        </div>
                       
                {/* <SplashBanner /> */}
                    </div>
                </div>
                <div className="container">
                <div className="row">
  <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
   
    <figure style={{ display: "inline-block", width: "100%" }}>
      <img src={process.env.PUBLIC_URL + "/images/cdio/kahina.jpg"} alt="tunisia" style={{ width: "100%" }} />
    </figure>
  </div>
  <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
  <div style={{ width: 60, height: 75, borderRadius: "20%", backgroundColor: "#549C9C", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginRight: "20px" }}>
  <span style={{ color: "#fff", fontSize: 17 }}>
  <strong>11</strong>  
  </span>
  <span style={{ color: "#fff", fontSize: 17 }}>
   <strong>12</strong> 
  </span>
  <span style={{ color: "#fff", fontSize: 17 }}>
  <strong>JUNE</strong>  
  </span>
</div>
  <h2 style={{ fontSize: "30px", fontWeight: "700", lineHeight: "40px", color: "#000", margin: "0 0 20px" }}>
    SOUK AT ESPRIT
  </h2>
</div>
  <p style={{ textAlign: "justify", maxWidth: "100%", alignSelf: "flex-start" ,margin: "0 0 20px"}}>
    Discover the exquisite craftsmanship of talented women artisans as they proudly present their authentic and locally-made products. This initiative aims to promote entrepreneurship and facilitate the socio-economic integration of women and youth from disadvantaged neighborhoods. Every purchase you make directly supports local artisans and contributes to the preservation of our cultural heritage. We collaborated with Enda Inter-Arabe, a leading NGO dedicated to promoting economic, financial, social, and cultural inclusion in Tunisia.
  </p>
  <a href="https://soukelkahina.tn/fr/content/4-a-propos" className="axil-btn btn-fill-primary" target='_blank' style={{  padding: "20px 20px", fontSize: "15px", width: "150px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>Read More</a>
  </div>
</div>
                         
<div className="row social-event-bottom" style={{marginTop : "10px"}}>
  <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
   
    <figure style={{ display: "inline-block", width: "100%" }}>
      <img src={process.env.PUBLIC_URL + "/images/cdio/lebaron.jpg"} alt="tunisia" style={{ width: "100%" }} />
    </figure>
  </div>
  <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
  <div style={{ width: 60, height: 75, borderRadius: "20%", backgroundColor: "#549C9C", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginRight: "20px" }}>
  
  <span style={{ color: "#fff", fontSize: 17 }}>
   <strong>10</strong> 
  </span>
  <span style={{ color: "#fff", fontSize: 17 }}>
  <strong>JUNE</strong>  
  </span>
</div>
  <h2 style={{ fontSize: "30px", fontWeight: "700", lineHeight: "40px", color: "#000", margin: "0 0 20px" }}>
  Baron d’Erlanger
  </h2>
</div>
  <p style={{ textAlign: "justify", maxWidth: "100%", alignSelf: "flex-start" ,margin: "0 0 20px"}}>
  Built in the beginning of the XXth century on the hill of Sidi Bou Saïd, giving out on a striking panorama, at once majestic and sober, the former residence of Rodolphe d’Erlanger stands as  a majestic tribute to Arabic-Andalusian heritage. Besides his architectural prowess, he was a gifted painter whose portraits evoke subtle melancholy, capturing the essence of emotion with unparalleled finesse. Furthermore, d’Erlanger was an incomparable memorialist of Arab musical heritage, leaving behind a rich legacy that resonates through time. To honor this , a concert featuring traditional instruments will resonate within the palace, immersing the audience in the enchanting melodies of Tunisia. This harmonious journey promises a rich tapestry of Tunisian musical traditions, enriched with good vibes, connecting attendees to the profound cultural heritage embedded in d’Erlanger's creation.
   </p>
  <a href="http://www.cmam.tn/visite_virtuelle/fr.html" className="axil-btn btn-fill-primary" target='_blank' style={{  padding: "20px 20px", fontSize: "15px", width: "150px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>Read More</a>
  </div>
</div>    
<div className="row" style={{marginTop : "10px"}}>
  <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
   
    <figure style={{ display: "inline-block", width: "100%" }}>
      <img src={process.env.PUBLIC_URL + "/images/cdio/tulip.jpg"} alt="tunisia" style={{ width: "100%" }} />
    </figure>
  </div>
  <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
  <div style={{ width: 60, height: 75, borderRadius: "20%", backgroundColor: "#549C9C", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginRight: "20px" }}>
  
  <span style={{ color: "#fff", fontSize: 17 }}>
   <strong>11</strong> 
  </span>
  <span style={{ color: "#fff", fontSize: 17 }}>
  <strong>JUNE</strong>  
  </span>
</div>
  <h2 style={{ fontSize: "30px", fontWeight: "700", lineHeight: "40px", color: "#000", margin: "0 0 20px" }}>
  GOLDEN CARTHAGE HOTEL
  </h2>
</div>
  <p style={{ textAlign: "justify", maxWidth: "100%", alignSelf: "flex-start" ,margin: "0 0 20px"}}>
  Indulge in Tunisia culinary treasures  surrounded by the breathtaking beauty of the Mediterranean Sea. Our traditional dishes have been influenced by a variety of cultures over the centuries. The cuisine of Tunisia is a delightful blend of Mediterranean, North African, and Middle Eastern flavors, resulting in a unique and unforgettable taste.
   </p>
  <a href="https://www.goldencarthage.com/en/home/" className="axil-btn btn-fill-primary" target='_blank' style={{  padding: "20px 20px", fontSize: "15px", width: "150px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>Read More</a>
  </div>
</div>  
            </div>
                       
                                 
            </section>
           
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default SocialEvents;