import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';


const BannerTwo = () => {
    return (
        <div className="banner banner-style-2" >
            <div className="container-fluid">
           
            <div className="row">
  <div className="col-md-8">
    {/* Content for the first 8 columns */}
  </div>
 
</div>

                       
                          
                    
             
            </div>
          
        </div>
    )
}

export default BannerTwo;