import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import BreadCrumbOne from "../../elements/breadcrumb/BreadCrumbOne";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";


const extractTimeStart = (timestamp, sessionType , sessionName) => {
  const dateObj = new Date(timestamp);
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();

  // Subtract 10 minutes from start time if sessionType is "PPS 1"
  if (sessionType === "PPS 1") {
    minutes -= 10;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
  }
  if (sessionName === "Round Tables: Educational Development and Collaborative Learning") {
    minutes -= 10;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
  }
  // Ensure minutes are padded correctly
  minutes = minutes.toString().padStart(2, "0");
  hours = hours.toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};
const extractTimeEnd = (timestamp, sessionType , sessionName) => {
  const dateObj = new Date(timestamp);
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();

  // Subtract 10 minutes from start time if sessionType is "PPS 1"
  if (sessionType === "PPS 1") {
    minutes -= 10;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
  }
  if (sessionType === "PPS 4") {
    minutes += 10;
      if (minutes >= 60) {
        hours += 1;
        minutes -= 60;
      }
  }
  if (sessionName === "Round Tables: Educational Development and Collaborative Learning") {
    minutes -= 10;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
  }
  // Ensure minutes are padded correctly
  minutes = minutes.toString().padStart(2, "0");
  hours = hours.toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

const sessionColors = {
  "RoundTable": "#C90384", // Tomato
  "Workshops": "#F9A91C", // Steel Blue
  "Working groups": "#8F2E5D", // Lime Green
  "Paper session": "#689108", // Default color
  "CDIO intro": "#ADADAD", // Gold
};

const ParallelSessionTuesday = () => {
  const [sessions, setSessions] = useState([]);
  console.log(sessions)
  useEffect(() => {
    const fetchProgramData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/presentationsByDate`,
          {
            date: "2024-06-11",
          }
        );

        // Group presentations by session type and start/end date time
        const groupedSessions = {};
        response.data.forEach((presentation) => {
          let sessionType;
          switch (presentation.session.type) {
            case "CDIO intro":
              sessionType = "CDIO Introduction";
              break;
            case "Paper session":
              if (presentation.session.session_title =="PPS 1") {
                sessionType = "Parallel Session 1";
              } else if (presentation.session.session_title =="PPS 2") {
                sessionType = "Parallel Session 2";
              } else if (presentation.session.session_title =="PPS 3") {
                sessionType = "Parallel Session 3";
              }else if (presentation.session.session_title =="PPS 4") {
                sessionType = "Parallel Session 4";
              }
              else if (presentation.session.session_title =="PPS 5") {
                sessionType = "Parallel Session 5";
              }
              else if (presentation.session.session_title =="PPS 6") {
                sessionType = "Parallel Session 6";
              } else if (presentation.session.session_title =="PPS 7") {
                sessionType = "Parallel Session 7";
              } 
              else {
                sessionType = "Parallel Session";
              }
              break;
            case "RoundTable":
              sessionType = "Parallel Round Tables";
              break;
            case "Workshops":
              sessionType = "Parallel Workshop Session";
              break;
            case "Working groups":
              sessionType = "Parallel Working Groups";
              break;
            default:
              sessionType = "Parallel Session";
          }

          const sessionKey = `${extractTimeStart(
            presentation.session.startDateTime,
            presentation.session.session_title,
            presentation.theme
          )}-${extractTimeEnd(
            presentation.session.endDateTime,
            presentation.session.session_title,presentation.theme
          )} ${sessionType}`;
          
          const sessionTime = `${extractTimeStart(
            presentation.session.startDateTime,
            presentation.session.session_title,presentation.theme
          )}-${extractTimeEnd(
            presentation.session.endDateTime,
            presentation.session.session_title,presentation.theme
          )}`;
          const sessionName = `${sessionType}`;
          const sessionDescription = presentation.session.type;
          console.log(sessionTime)
          if (!groupedSessions[sessionKey]) {
            groupedSessions[sessionKey] = {
              session_type: sessionDescription,
              time: sessionTime,
              session_name: sessionName,
              presentations: [],
              themes: {},
            };
          }

          if (presentation.theme) {
            if (!groupedSessions[sessionKey].themes[presentation.theme]) {
              groupedSessions[sessionKey].themes[presentation.theme] = [];
            }
            groupedSessions[sessionKey].themes[presentation.theme].push(presentation);
          } else {
            groupedSessions[sessionKey].presentations.push(presentation);
          }
        });

        // Convert object to array and sort by startDateTime
        const sessionsArray = Object.values(groupedSessions).sort((a, b) => {
          const aStart = new Date(
            response.data.find(
              (p) =>
                `${extractTimeStart(p.session.startDateTime,p.session.session_title,p.theme)}-${extractTimeEnd(
                  p.session.endDateTime,p.session.session_title,p.theme
                )}` === a.time
            ).session.startDateTime
          );
          const bStart = new Date(
            response.data.find(
              (p) =>
                `${extractTimeStart(p.session.startDateTime,p.session.session_title,p.theme)}-${extractTimeEnd(
                  p.session.endDateTime,p.session.session_title,p.theme
                )}` === b.time
            ).session.startDateTime
          );
          return aStart - bStart;
        });

        setSessions(sessionsArray);
      } catch (error) {
        console.error("Error fetching program data:", error);
      }
    };

    fetchProgramData();
  }, []);

  return (
    <>
      <SEO title="Parallel sessions Tuesday" />
      <main className="main-wrapper">
        <HeaderTwo />
        <BreadCrumbOne
          title="Parallel sessions Tuesday"
          page="Programme"
          subtitle="Subject to changes."
        />

        <div className="section section-padding expert-counterup-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8">
                {sessions.map((session, index) => (
                  <div key={index} className="section section-padding">
                    <div>
                      <h5 style={{ display: "inline", marginRight: "10px" }}>
                        {session.time}
                      </h5>
                      <h5
                        style={{
                          display: "inline",
                          color:
                            sessionColors[session.session_type.split(" - ")[0]] ||
                            sessionColors["Parallel Session"],
                        }}
                      >
                        {session.session_name}
                      </h5>
                    </div>
                    <Accordion flush>
                      {Object.entries(session.themes).map(([theme, presentations], idx) => (
                        <Accordion.Item key={`theme-${idx}`} eventKey={`theme-${idx}`}>
                          <Accordion.Header>
                            <p
                              style={{
                                fontSize: "20px",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                          {theme}
                            </p>
                          </Accordion.Header>
                         {session.session_type==="Paper session" ?
                         
                         
                         <Accordion.Body>
                          
                          {presentations.map((presentation, pIdx) => (
  <div key={pIdx}>
    {pIdx === 0 && (
      <p style={{ margin: '0', padding: '0', color: "#549C9C" }}>
        Room {presentation.room}
      </p>
    )}
    {pIdx === 0 && (<p style={{ margin: '0', padding: '0' }}> {presentation.session_chair_name ? "Session Chair: " +presentation.session_chair_name : "Session Chair"}</p>)}<br/>
    <p style={{ margin: '0', padding: '0' }}>{presentation.startTime}-{presentation.endTime}</p>
  
    <p style={{ margin: '0', padding: '0' }}>Paper {presentation.paper_number}</p>
    <p style={{ margin: '0', padding: '0' }}>
    {presentation.document ?   <strong>
                                  <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_API_URL}${presentation.document}`} target="_blank"> {presentation.paper_title}   </a> 
                                 
                                  </strong>: <strong>{presentation.paper_title} </strong>}
    </p>
    <p style={{ margin: '0', padding: '0' }}>{presentation.Authors}</p>
   
  </div>
))}

                       </Accordion.Body> :
                         
                         <Accordion.Body>
                         {presentations.map((presentation, pIdx) => (
                             <div key={pIdx}>
                             <p style={{ margin: '0', padding: '0',color:"#549C9C" }}>
                             Room {presentation.room}     
                             </p>
                             <p style={{ margin: "0", padding: "0" }}>Paper {presentation.paper_number}</p>
                             <p style={{ margin: "0", padding: "0" }}>
                          
                               {presentation.document ?   <strong>
                                  <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_API_URL}${presentation.document}`} target="_blank"> {presentation.paper_title}   </a> 
                                 
                                  </strong>: <strong>{presentation.paper_title} </strong>}  
                            
                             </p>
                             <p style={{ margin: "0", padding: "0" }}>
                             {presentation.Authors}      
                             </p>
                             <br/>
                           </div>
                          
                         ))}
                       </Accordion.Body> 
                          }
                         
                        </Accordion.Item>
                      ))}
                      {session.presentations.map((presentation, idx) => (
                        <Accordion.Item key={idx} eventKey={`no-theme-${idx}`}>
                          {renderPresentationContent(presentation, session.session_type)}
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                ))}
              </div>
              <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>
            </div>
          </div>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ParallelSessionTuesday;

const renderPresentationContent = (presentation, sessionType) => {
  switch (sessionType) {
    case "Working groups":
      return (
        <>
          <Accordion.Header>
            <p
              style={{
                fontSize: "20px",
                margin: "0",
                padding: "0",
              }}
            >
              {presentation.document ?   
                                  <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_API_URL}${presentation.document}`} target="_blank"> {presentation.paper_title}   </a> 
                                 
                                  : <>{presentation.paper_title}</> } 
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ margin: '0', padding: '0',color:"#549C9C" }}>
              Room {presentation.room}
              </p>
              <p style={{ margin: "0", padding: "0" }}>
                Paper {presentation.paper_number}
              </p>

              <p style={{ margin: "0", padding: "0" }}>{presentation.Authors}</p>
            </div>
          </Accordion.Body>
        </>
      );
    case "CDIO intro":
        return (
          <>
            <Accordion.Header>
              <p
                style={{
                  fontSize: "20px",
                  margin: "0",
                  padding: "0",
                }}
              >
                {presentation.document ?   
                                  <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_API_URL}${presentation.document}`} target="_blank"> {presentation.paper_title}   </a> 
                                 
                                  : <>{presentation.paper_title}</> } 
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <p style={{ margin: '0', padding: '0',color:"#549C9C" }}>
                Room {presentation.room}
                </p>
                {presentation.Authors ?  <p style={{ margin: "0", padding: "0" }}>{presentation.Authors}</p> : <></>}  
  
               
              </div>
            </Accordion.Body>
          </>
        );
      
  }
};

