import React from 'react';
import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import SectionTitle from '../../elements/section-title/SectionTitle';

const ConferenceApp = () => {
    return (
        <>
            <SEO title="Conference app" />
            <main className="main-wrapper">
                <HeaderTwo />
                <BreadCrumbOne 
                    title="Conference app"
                    page="Information"
                />
                <div className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8">
                                
                                <p>
                                    To download the app, please click on this &nbsp; 
                                    <a href="Cdioesprit.apk" target="_blank" rel="noopener noreferrer" download>  link</a>. <br/>
                                    You can connect using the credentials sent to you during registration.
                                </p>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="contact-form-box shadow-box">
                                    <h5>Organisers: </h5>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
                                        alt="esprit logo"
                                        style={{
                                            display: "block",
                                            margin: "0 auto",
                                            maxWidth: "80%",
                                            height: "auto",
                                        }}
                                    />
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
                                        alt="cdio logo"
                                        style={{
                                            display: "block",
                                            margin: "0 auto",
                                            maxWidth: "80%",
                                            height: "auto",
                                        }}
                                    />
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
                                        alt="unesco logo"
                                        style={{
                                            display: "block",
                                            margin: "20px auto 0",
                                            maxWidth: "80%",
                                            height: "auto",
                                        }}
                                    />
                                    <h5>Sponsors:</h5>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
                                        alt="devoteam logo"
                                        style={{
                                            display: "block",
                                            margin: "20px auto 0",
                                            maxWidth: "80%",
                                            height: "auto",
                                        }}
                                    />
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
                                        alt="stb logo"
                                        style={{
                                            display: "block",
                                            margin: "20px auto 0",
                                            maxWidth: "80%",
                                            height: "auto",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ConferenceApp;
