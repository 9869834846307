import React, { useEffect, useState } from 'react';
import FooterOne from '../common/footer/FooterOne';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import HeaderTwo from '../common/header/HeaderTwo';
import { Table } from 'react-bootstrap';
import axios from "axios";
import { Link } from 'react-router-dom';

export default function Program() {
  const [ProgramList, setProgramData] = useState(null);

  useEffect(() => {
      const fetchProgramData = async () => {
          try {
              const response = await axios.get(`${process.env.REACT_APP_API_URL}api/program`);
              setProgramData(response.data);
              console.log(response.data);
          } catch (error) {
              console.error('Error fetching program data:', error);
          }
      };

      fetchProgramData();
  }, []);
  if (!ProgramList) {
    return <div>Loading...</div>; // Ou tout autre indicateur de chargement
  }

  // Group sessions by date
  const sessionsByDate = {};
  ProgramList && ProgramList.length !== 0 && ProgramList.forEach(session => {
    const date = session.startDateTime.split('T')[0];
    if (!sessionsByDate[date]) {
      sessionsByDate[date] = [];
    }
    sessionsByDate[date].push(session);
  });

  // Get unique times and sort them in ascending order
  let uniqueTimes = [...new Set(ProgramList.map(session => session.startDateTime.split('T')[1].slice(0, 5)))];

  uniqueTimes.sort();
  const timeSlots = generateTimeSlots();

  const sessionsByDateAndTime = {};

  const sessionGrid = {};
  timeSlots.forEach(time => {
    sessionGrid[time] = {}; // Initialize grid for each time slot
    Object.keys(sessionsByDate).forEach(date => {
      sessionGrid[time][date] = []; // Initialize grid for each date
    });
  });
  Object.keys(sessionsByDate).forEach(date => {
    const sessions = sessionsByDate[date];
    sessions.forEach(session => {
      const sessionStartTime = session.startDateTime.split('T')[1].slice(0, 5);
      const sessionEndTime = session.endDateTime.split('T')[1].slice(0, 5);

      // Parse the start and end times into Date objects
      const sessionStart = new Date(`1970-01-01T${sessionStartTime}Z`);
      const sessionEnd = new Date(`1970-01-01T${sessionEndTime}Z`);

      // Calculate the difference in minutes
      const differenceInMinutes = (sessionEnd - sessionStart) / (1000 * 60);

      // Calculate the number of 30-minute slots
      const rowspan = differenceInMinutes / 30;

      if (!sessionsByDateAndTime[date]) {
        sessionsByDateAndTime[date] = {};
      }
      if (!sessionsByDateAndTime[date][sessionStartTime]) {
        sessionsByDateAndTime[date][sessionStartTime] = {};
      }
      if (!sessionsByDateAndTime[date][sessionStartTime][sessionEndTime]) {
        sessionsByDateAndTime[date][sessionStartTime][sessionEndTime] = {};
      }

      // Add the rowspan to the session
      sessionsByDateAndTime[date][sessionStartTime].rowspan = rowspan;
    });
  });

  // Populate the grid with session data
  Object.keys(sessionsByDate).forEach(date => {
    sessionsByDate[date].forEach(session => {
      const sessionStartTime = session.startDateTime.split('T')[1].slice(0, 5);
      const sessionEndTime = session.endDateTime.split('T')[1].slice(0, 5);
      const sessionContent = session.session_title;

      // Find the time slot that the session belongs to
      const timeSlot = timeSlots.find(time => time >= sessionStartTime && time < sessionEndTime);

      // Push session content to the corresponding cell in the grid
      if (timeSlot) {
        sessionGrid[timeSlot][date].push(sessionContent);
      }
    });
  });

  const maxSessionLengthByDate = Object.keys(sessionsByDate).reduce((max, date) => {
    const lengths = Object.values(sessionGrid).map(timeGrid => {
      return (timeGrid[date] && timeGrid[date].length) || 0;
    });
    const maxSessionLength = Math.max.apply(Math, lengths);
    return { ...max, [date]: maxSessionLength };
  }, {});

  const sessionColumns = Array(Object.keys(sessionsByDate).length).fill(null);

  return (
    <>
      <SEO title="Programme" />
      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderTwo />
        <BreadCrumbOne
          title="Programme"
          page="Programme"
        />

        <br />
        <div className="section section-padding">
        <div className="container">
                    <p style={{ margin: '0', padding: '0' }}>See details about parallel sessions by in the dropdown menu on the top of the page by using the links below. You can also visit <a href='https://www.conftool.pro/cdio2024/sessions.php' target='_blank'>this link</a> for more information</p>
                    <Link to='/programme-monday'>Parallel sessions Monday</Link><br/>
                    <Link to='/programme-tuesday'>Parallel sessions Tuesday</Link><br/>
                    <Link to='/programme-wednesday'>Parallel sessions Wednesday</Link><br/>
                    <Link to='/programme-thursday'>Parallel sessions Thursday</Link><br/>
                    <br/>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="table-responsive">
                  <Table border="1px">
                    <thead align="center">
                      <tr>
                        <th>Time</th>
                        {Object.keys(sessionsByDate).map(date => (
                          <th key={date} style={{ fontSize: "13px" }} colSpan={maxSessionLengthByDate[date]}>
                            {new Date(date).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="row-hover">
                      {timeSlots.map((time, timeIndex) => (
                        <tr key={timeIndex} align="center">
                          <td align="center" style={{ fontSize: "13px", textAlign: 'center', verticalAlign: 'middle' }}>
                            {`${time} - ${endTime(time)}`}
                          </td>
                          {Object.keys(sessionsByDate).map(date => {
                            const sessionContent = sessionGrid[time][date];

                            return sessionContent.map((content, index) => {
                              // Find the session from ProgramList that matches the content
                              const s = ProgramList.find(session => session.startDateTime.includes(date) && session.startDateTime.includes(time) && session.session_title === content);

                              let backgroundColor = 'white'; // Default color
                              let textColor = 'black';
                              let rowspan = 1;
                              let colspan = 1;

                              if (s) {
                                rowspan = s.rowspan;
                                colspan = s.colspan;

                                switch (s.type) {
                                  case 'Blank':
                                    backgroundColor = '#FFFFFF';
                                    textColor = 'black';
                                    break;
                                  case 'Front desk':
                                    backgroundColor = '#E8E8E8';
                                    textColor = 'black';
                                    break;
                                  case 'Workshops':
                                    backgroundColor = '#F9A91C';
                                    textColor = 'white';
                                    break;
                                  case 'Break':
                                    backgroundColor = '#0E4861';
                                    textColor = 'white';
                                    break;
                                  case 'Custom session':
                                    backgroundColor = '#747474';
                                    textColor = 'white';
                                    break;
                                  case 'Keynote session':
                                    backgroundColor = '#AB4550';
                                    textColor = 'white';
                                    break;
                                  case 'CDIO':
                                    backgroundColor = '#079C89';
                                    textColor = 'white';
                                    break;
                                  case 'Social Event':
                                    backgroundColor = '#30A2C1';
                                    textColor = 'white';
                                    break;
                                  case 'Working groups':
                                    backgroundColor = '#8F2E5D';
                                    textColor = 'white';
                                    break;
                                  case 'Paper session':
                                    backgroundColor = '#689108';
                                    textColor = 'white';
                                    break;
                                  case 'RoundTable':
                                    backgroundColor = '#C90384';
                                    textColor = 'white';
                                    break;
                                  case 'Project fair':
                                    backgroundColor = '#09769F';
                                    textColor = 'white';
                                    break;
                                  case 'Plenary':
                                    backgroundColor = '#D67642';
                                    textColor = 'white';
                                    break;
                                  case 'CDIO intro':
                                    backgroundColor = '#ADADAD';
                                    textColor = 'white';
                                    break;
                                  default:
                                    backgroundColor = 'white'; // Default color
                                    break;
                                }
                              }

                              return (
                                <td
                                  align="center"
                                  key={`${time}-${date}-${index}`}
                                  rowSpan={rowspan}
                                  colSpan={colspan}
                                  style={{ backgroundColor, color: textColor, textAlign: 'center', verticalAlign: 'middle', border: '1px solid white' }}
                                  >
                                    {date ==="2024-06-10" && <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/programme-monday">{content} </Link> }
                                    {date ==="2024-06-11" && <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/programme-tuesday">{content} </Link> }
                                   
                                    {date ==="2024-06-12" && <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/programme-wednesday">{content} </Link> }
                                    {date ==="2024-06-13" && <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/programme-thursday">{content} </Link> }
                                   
                                  </td>
                              );
                            });
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterOne parentClass="" />
      </main>
    </>
  )
}

const endTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  let endHours = hours;
  let endMinutes = minutes + 30;

  // Check if end minutes exceed 59
  if (endMinutes >= 60) {
    endHours += 1;
    endMinutes -= 60;
  }

  // Convert end hours and minutes to string format with leading zeros if necessary
  endHours = endHours.toString().padStart(2, '0');
  endMinutes = endMinutes.toString().padStart(2, '0');

  return `${endHours}:${endMinutes}`;
};

const generateTimeSlots = () => {
  let timeSlots = [];
  for (let hours = 8; hours <= 21; hours++) { // changed hours condition to <= 21
    // Only generate slots until 21:00 for the last hour
    const lastMinute = hours === 21 ? 0 : 60; // changed lastMinute condition for 21 hours
    for (let minutes = 0; minutes < lastMinute; minutes += 30) {
      let time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      timeSlots.push(time);
    }
  }
  return timeSlots;
};
