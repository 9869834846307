import React from 'react';



import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import CaseCallForPaper from '../../component/casestudy/CaseCallForPaper';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import pdf1 from './WorkingGroupsOnCDIOWebsite/CDIO2024_Working_group_Learning_from_Failure.pdf'
import pdf2 from './WorkingGroupsOnCDIOWebsite/ADVANCING_THE_ROLE_OF_TECHNICANS_WITHIN_CDIO_PROGRAMMES.pdf'
import pdf3 from './WorkingGroupsOnCDIOWebsite/CDIO_2024_working_group_description_outline.pdf'
import pdf4 from './WorkingGroupsOnCDIOWebsite/CDIO2024_Working_group_INTRO_TO_CDIO.pdf'
import pdf5 from './WorkingGroupsOnCDIOWebsite/WORKING_GROUP_ENGINEERS_WHO_KNOW_HOW_TO_ENGINEER.pdf'
const WorkingGroups = () => {


    return (
        <>
        <SEO title="Working Groups" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Working Groups"
                page="Program"
                />
             <div className="section section-padding expert-counterup-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="section-heading heading-left">
                            {/* <span className="subtitle">September 2023</span> */}
                            {/* <h2>What makes us special?</h2> */}
                            <h3>General</h3>
                            <div className='row'>
                            <div className='col-lg-8'><p className="mb--50">
                            Working groups are formed by participants with a common interest in a topic related to the subject matter of the conference. The groups of five to ten participants work together electronically before the start of the conference. 
                           <strong> Working groups convene on Monday June 10th – the exact time will be shown in the Conference Programme. </strong>
                            The working groups start working digitally together prior to the conference; you can find detailed information about the activities in the working group descriptions below.

            </p>
            <p>Every working group member must register for and be present at the conference in order to be part of the working group and considered a contributor to the final report. 
The working groups present their preliminary results to conference attendees at a special working group presentation session on the last day of the conference and can optionally submit a state-of-the-art paper after the conference concludes.
                    </p>
            </div>
            <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>

                            </div>



                       
                          
                          
                       
					
                           <h3>The four Working Groups at the conference are :</h3>
                           <p> <strong>DESIGN PRINCIPLES OF ACTIVITIES BASED ON LEARNING FROM DELIBERATE FAILURE :</strong></p>
                         
                           <ul>

<li>
<a href={pdf1} target='_blank'>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        DESIGN PRINCIPLES OF ACTIVITIES BASED ON 
LEARNING FROM DELIBERATE FAILURE
        </a>
</li>



                           </ul>
                           <p> <strong>ADVANCING THE ROLE OF TECHNICANS WITHIN CDIO PROGRAMMES :</strong></p>
                         
                           <ul>

<li>
<a href={pdf2} target='_blank'>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        ADVANCING THE ROLE OF TECHNICANS WITHIN CDIO PROGRAMMES
        </a>
   </li>



                           </ul>
                          
                           <p> <strong>DESIGNING THE UNIVERSITY OF THE FUTURE – AN INTERNATIONAL PERSPECTIVE :</strong></p>
                         
                         <ul>

<li>
<a href={pdf3} target='_blank'>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        DESIGNING THE UNIVERSITY OF THE FUTURE – AN INTERNATIONAL PERSPECTIVE
        </a>
   </li>



                         </ul>
                         <p> <strong>DEVELOPMENT OF INTRODUCTORY WORKSHOPS AND SUPPORTING RESOURCES :</strong></p>
                         
                         <ul>

<li>
<a href={pdf4} target='_blank'>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        DEVELOPMENT OF INTRODUCTORY WORKSHOPS AND SUPPORTING RESOURCES
        </a>
    </li>



                         </ul>
                         
                         <h3>Applying to join :</h3>
                          <p>If you would like to participate in one of the working groups, please write an email to the working group leader mentioned in the description as soon as possible, but latest before April 28. They will evaluate the application and contact you. You will be notified about acceptance before May 7.</p>
                          <p>When applying you assure the conference organisers of your intention to register for and attend CDIO2024 and the working group leader to take active part in the working group before, under and if applicable after the CDIO 2024 conference.
                          <h3>Questions :</h3>
<p>If you have further questions about the actual working group, contact the working group leader. For general questions , contact  <strong>Jens Bennedsen</strong>  at <a href='mailto:jbb@ece.au.dk'> jbb@ece.au.dk</a></p>

</p>
<h3>Publication :</h3>
<p>Working group state-of-the-art papers will be submitted shortly after the conference is over and submitted for peer review. If accepted for publication as a result of the peer review, they will be added to the proceedings of the conference.</p>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default WorkingGroups;