import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import DateData from "../../data/pricing/DateData.json";
import { FaCheck} from "react-icons/fa";


const getPriceingData = DateData;

const billed = [
    {
        id: 1,
        label: "2021"
    },
    {
        id: 2,
        label: "2022"
    },
    {
        id: 3,
        label: "2023"
    }
]

const Date = () => {

    const [isActive, setActive] = useState("");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());
		
    }, []);


    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());
        
    };


    return (
        <>
           
            <div className="row">
                {getPriceingData.map((data, index) => (
                    <div className="col-lg-4" key={index}>
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                                <span className="title">{data.title}</span>
                                {/* <span className="subtitle">{data.subtitle}</span> */}
                                <div className="price-wrap">
                                  
                                    
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-unstyled">
                                    {data.facility.map((data, index) => (
                                        <li key={index}> <FaCheck /> {data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Date;