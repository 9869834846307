import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import BlogData from "../../data/blog/BlogData.json";


const allBlogData = BlogData;

const BlogListOne = ({colSize, itemShow}) => {
    return (
        <>
            {allBlogData.slice(0, itemShow).map((data) => (
                <div className={`${colSize}`} key={data.id}>
                    <div className={`blog-list ${(data.id % 2  === 0) ? "border-start" : ""}`}>
                       
                        <div className="post-content">
                            <h3 className="title">
                               
                                    {data.title}
                               
                            </h3>
                            <p>{data.excerpt}</p>
                            <a href="http://cdio.org/meetings-events/20th-international-cdio-conference-esprit-tunisia" className="more-btn" target="_blank">
                                Learn more <FaAngleRight />
                            </a>
                        </div>
                        <div className="post-thumbnail" align="center">
                           
                           <img src={`${process.env.PUBLIC_URL}/${data.thumb}`} alt="cdio logo"  width='70%' height='40%'/>
                      
                   </div>
                    </div>
                </div>
            ))}
           
        </>
    )
}

export default BlogListOne;