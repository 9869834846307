import React from 'react';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import FooterOne from '../common/footer/FooterOne';



const ConferenceTopics = () => {


    return (
        <>
        <SEO title="Conference Topics" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Conference Topics"
                page="About"
                />
            <div className="section section-padding">
                    <div className="container">
                            <div className="row">
                            <div className="col-xl-8 col-lg-8">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            
                            <h6 className="title">Main Theme of this edition : </h6>
                            <ul style={{paddingTop : "10px"}}>
                                <li>AI Ethics in Engineering Curriculum</li>
                                <li>AI-Driven Pedagogical Approaches</li>
                                <li>Incorporating AI Tools in Engineering Labs</li>
                            </ul>
                            <br></br>
                            <h6>Specific topics of interest for this year’s conference are: </h6>
                            <ul>

                                <li>Engineering in the Quantum Computing Era</li>
                                <li>Incorporating Sustainability and Climate Change in engineering education</li>
                                <li>Lifelong learning for Engineers</li>
                                <li>Emotion and reason in Engineering Ethics</li>
                                <li>Operationalization of the CDIO Syllabus and standards</li>
                            </ul>
                            <br></br>
                            <h6>Further suggestions for contributions: </h6>
                            <p>Proposals for contributions are also welcome on any topic relevant to the CDIO approach and the conference theme, such as: new developments in Engineering Education; Curriculum and Course Development; Engineering Projects and Introduction to Engineering; Learning Environments –Online and On-site, Preparing for the Future; Faculty Development; Student Engagement;
Internationalization. Remember that all contributions need to have a connection to at least one of <a href='http://www.cdio.org/implementing-cdio/standards/12-cdio-standards' target='_blank'>the CDIO Standards</a>.</p>
                             </div>
                       
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
      alt="stb logo"
      style={{
        display: "block",
        margin: "20px auto 0",
        maxWidth: "80%",
        height: "auto",
      }}
    />
  </div>
</div>

                            </div>
                       
                        
                    </div>
                    
                   
                </div>
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ConferenceTopics;