import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import SEO from '../common/SEO';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import HeaderTwo from '../common/header/HeaderTwo';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import CaseCallForPaper from '../component/casestudy/CaseCallForPaper';
import SectionTitle from '../elements/section-title/SectionTitle';


const CallforpaperSite = () => {


    return (
        <>
        <SEO title="Call For Papers" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Submission Link"
                page="Information for authors
"
                />
             <div className="section section-padding">
                    <div className="container">
                        <SectionTitle 
                            // subtitle="Submission Link"
                            title="The submission link will be available on october 20th."
                            description=""
                            textAlignment="mb-0"
                            textColor=""
                        />
                        {/* <PricingOne /> */}
                        {/* <ProcessOne /> */}
                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                    </ul>
                </div>
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default CallforpaperSite;