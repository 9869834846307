import React from 'react';
import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import CaseCallForPaper from '../../component/casestudy/CaseCallForPaper';
import SectionTitle from '../../elements/section-title/SectionTitle';


const PracticalInformation = () => {


    return (
        <>
        <SEO title="Practical Information" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Practical Information"
                page="Information"
                />
          <div className="section section-padding expert-counterup-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className='row'>

                    <div className="col-lg-8">
                        <div className="section-heading heading-left">
                           
                        <h4>When ?</h4>
                            <p > from 10 to 13 June 2024 </p>
                            <h4>Where ?</h4>
                            <p > ESPRIT Private School of Engineering   </p>
                       
                       <h4>How to attend</h4>
                       <p>  Registration opens January 2024.</p>
                       <h4>Travel to Tunisia</h4>
                       <p>Getting here is easy.</p>
                       <a href='/travel-to-tunisia'>View our "Travel to Tunisia" page</a>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>
                    </div>
                    
                 
                </div>
            </div>
        </div>
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default PracticalInformation;