import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import BlogListOne from "./BlogListOne";
import FormTwo from "../contact/FormTwo";

const BlogOne = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <BlogListOne colSize="col-xl-12" itemShow="1" />
        </div>
        <br></br> <br></br>
        <div className="row">
          <div className="col-xl-8 col-lg-8">
            <div className="section section-padding">
              <SectionTitle
                subtitle="Organisers"
                title="ESPRIT"
                description="We are delighted to invite you to The 20th CDIO International Conference, which will be held from 10 to 13 June 2024 and will be hosted by  Esprit .The main theme of the conference is “Engineering Education in the Era of AI."
                textAlignment="heading-left"
                textColor=""
              />

              {/* <p align="center">We are looking forward to seeing you at the conference.</p> */}
            </div>
          </div>
          <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
      alt="stb logo"
      style={{
        display: "block",
        margin: "20px auto 0",
        maxWidth: "80%",
        height: "auto",
      }}
    />
  </div>
</div>
        </div>
        <ul className="shape-group-1 list-unstyled">
          {/* <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="bubble" /></li> */}
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
              alt="bubble"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-2.png"}
              alt="bubble"
            />
          </li>
          {/* <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="bubble" /></li> */}
        </ul>
      </div>
    </div>
  );
};

export default BlogOne;
