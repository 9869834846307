import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyData from '../../data/casestudy/CaseStudyData.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faFileWord } from '@fortawesome/free-solid-svg-icons'; 
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import { slugify } from '../../utils';
import Date from '../pricing/Date';
import documentUrl from "../../documents/CALL FOR PAPERS.pdf"
import documentUrl1 from "../../documents/Authors-Criteria-EER-CDIO-2024.pdf"
import documentUrl2 from "../../documents/Authors-Criteria-Advances-CDIO-2024.pdf"
import documentUrl3 from "../../documents/Authors-Criteria-Implementation-CDIO-2024.pdf"
import documentUrl4 from "../../documents/Authors-Criteria-PiP-CDIO-2024.pdf"

import documentUrl5 from "../../documents/CDIO2024_New_Workshops_template.docx"
import documentUrl6 from "../../documents/CDIO2024_Roundtables_template.docx"
import documentUrl7 from "../../documents/CDIO2024_Working_group_template.docx"
import documentUrl8 from "../../documents/CDIO2024_Papers_template.dotx"
const allData = CaseStudyData;

const CaseCallForPaper= () => {
    

    return (
        <div className="section section-padding expert-counterup-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="section-heading heading-left">
                            <span className="subtitle">September 2023</span>
                            {/* <h2>What makes us special?</h2> */}
                            <h3>Call for papers</h3>
                            <div className='row'>
                            <div className='col-lg-8'>                             <p className="mb--50">The CDIO approach <a href='www.cdio.org' target='_blank'> (www.cdio.org)</a> offers an innovative educational framework designed to
nurture the future engineers. It is a transformative framework designed to cultivate the
forthcoming generation of engineers, emphasizing the real-world application of Conceiving,
Designing, Implementing, and Operating. With its roots in global collaboration, the model
integrates engineering fundamentals and has been widely incorporated into curricular designs
and assessments by institutions worldwide. </p>
<p>Stemming from a collaboration between educators, industry experts, and students, the CDIO
model offers a flexible, open-architecture design. This adaptability has led to its widespread
implementation across various engineering disciplines.</p>
<p>The CDIO community, a consortium of educational institutions, values the diverse pathways of
engineering education. This network includes a wide range of institutions, from elite research
universities to foundational engineering colleges, all focused on collaborative learning.
Alongside this, CDIO collaborators actively develop and disseminate educational resources,
ensuring the continual evolution and adoption of the CDIO approach.</p>
<p>The CDIO Initiative welcomes your ideas and invites proposals for the forthcoming 20 th
International CDIO Conference. Researchers, educators, and professionals are invited to submit
manuscripts, propose working group collaborations, host specialist workshops, and engage in
academic roundtable discussions. This pivotal gathering is scheduled from June 10 to June 13,
2024, in Tunis, Tunisia. The inaugural day is dedicated to CDIO introductory sessions,
collaborative workshops, working group deliberations, and networking events. Additional
information on the conference is available at : <a href='https://cdio.esprit.tn' target='_blank'>https://www.cdio.esprit.tn </a>.</p></div>
<div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
      alt="stb logo"
      style={{
        display: "block",
        margin: "20px auto 0",
        maxWidth: "80%",
        height: "auto",
      }}
    />
  </div>
</div>

                            </div>


<a href={documentUrl} download>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        View detailed Call for Papers with guidelines and submission details.
      </a>
                       
                           <h3>Submissions</h3>
                           <p>All submissions via the SoftConf Abstract and Paper Submission system (you will be able to submit your abstracts by 20 October 2023).</p>
                           <a href="https://www.conftool.pro/cdio2024" target='_blank'>Go to submissions.</a>
                           <h3>Main conference theme </h3>
                           <p style={{color:"#549C9C"}}><strong>Engineering Education in the Era of AI</strong></p>
                          <p>The central theme of this year&#39;s conference revolves around &quot;Engineering education in the era of
AI.&quot; We welcome contributions that align with the CDIO approach and the overarching theme.
We encourage various presentation formats including Podium Presentations and Poster
Presentations. For those interested in Workshops, Working Groups, and Roundtable Discussions,
note that a full paper isn&#39;t mandatory. Please refer to the sections below for comprehensive
details about the contribution categories and presentation styles.</p>
                           <h3>Conference Themes</h3>
                           <p>Building on the core theme, we&#39;re keen to explore submissions that delve into the following
focus areas: </p>
<ul>
    <li>AI Ethics in Engineering Curriculum</li>
    <li>AI-Driven Pedagogical Approaches</li>
    <li>Incorporating AI Tools in Engineering Labs</li>
    {/* <li>Skills for the Future Engineer</li>
    <li>The Intersection of AI and Traditional Engineering Disciplines</li>
    <li>AI, Robotics, and Engineering Design</li> */}
</ul>
<p>Specific topics of interest for this year’s conference are:</p>
<ul>
    <li>Engineering in the Quantum Computing Era</li>
    <li>Incorporating Sustainability in engineering education and Climate Change</li>
    <li>Lifelong learning for Engineers</li>
    <li>Emotion and reason in Engineering Ethics</li>
    <li>Operationalization of the CDIO Syllabus and standards</li>
    
    
</ul>
{/* <p>Classic topics:</p>
<ul>

    <li>Design-Based Learning.</li>
    <li>Experiential Learning Environments</li>
    <li>Continuous Improvement in Curriculum Design</li>
    <li>Stakeholder Engagement in Curriculum Development</li>
    <li>Assessment and Evaluation Methods</li>
    <li>Integrating Soft Skills in Engineering Education</li>
</ul> */}
                           <h3>Important dates & deadlines</h3>
                           <Date/>
                         <p>* Papers in the EER (Engineering Education Research) track are reviewed again after the
submission of the final paper if the draft paper submission was accepted with “minor changes”
or “major changes”. The focus of the review is to ensure appropriate development of the
manuscript and response to reviewer feedback.</p>
					
                           <h3>General Criteria for Selection</h3>
                           <ul>

<li>Relevance to the conference theme and tracks</li>
<li>Novelty of innovative ideas with proven results</li>
<li>Potential to stimulate thought and discussion</li>
<li>Applicability of ideas, programs, and practices to the CDIO community</li>
<li>Compliance with the criteria for the particular submission tracks</li>


                           </ul>
                           <h3>Criteria for the different tracks</h3>
                           <ul>
                            <li>
                            <a href={documentUrl1} download>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        EER Paper Criteria
      </a> 
                                
                               </li>
                            <li>
                            <a href={documentUrl2} download>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        Advances in CDIO Criteria
      </a> 
                                
                           </li>
                            <li>
                            <a href={documentUrl3} download>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        CDIO Implementation Criteria
      </a> 
                              </li>
                            <li>
                            <a href={documentUrl4} download>
        <FontAwesomeIcon icon={faFilePdf} />  &nbsp;
        CDIO Project in Progress Criteria
      </a> 
                                </li>

                           </ul>

                           <h3>Templates for papers and activities</h3>
                           <li>
                            
                           <a href={documentUrl5} download>
        <FontAwesomeIcon icon={faFileWord} />  &nbsp;
        Workshop template
      </a>
                            
                             </li>
                           <li>
                           <a href={documentUrl6} download>
        <FontAwesomeIcon icon={faFileWord} />  &nbsp;
        Roundtable template
      </a>
                           </li>
                           <li>
                           <a href={documentUrl7} download>
        <FontAwesomeIcon icon={faFileWord} />  &nbsp;
        Working group template
      </a>
                           </li>
                           <h3>Templates for full papers</h3>
                           <ul>
                            <li> <a href={documentUrl8} download>
        
                            MS Word paper template
      </a> </li>
                            <li><a href='https://www.overleaf.com/latex/templates/cdio-conference-2023-submission-template/gwwxzykqxsjf' target='_blank'>Overleaf/LaTeX paper template</a></li>
                           </ul>
                           <p>There is no template for the abstract as the abstract is entered direct into the SoftConf Abstract and Paper Submission system.</p>
                      

                         





                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
    )
}

export default CaseCallForPaper;