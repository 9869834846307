import React from 'react';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import FooterOne from '../common/footer/FooterOne';
import { Link } from 'react-router-dom';



const Registration = () => {


    return (
        <>
        <SEO title="Registration" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Registration"
                page="Registration"
                />
            <div className="section section-padding">
                    <div className="container">
                            <div className="row">
                            <div className="col-xl-8 col-lg-8">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            
                            <h6 className="title" style={{color:"#549C9C"}} > <strong>Early registration:</strong> <strong style={{color:"black"}}>2100,00 TND</strong> </h6> 
                            <h6 className="title" style={{color:"#549C9C"}}> <strong>Registration after 30 April 2024:</strong> <strong style={{color:"black"}}>2300,00 TND</strong></h6>
                            <br></br>
                            <h6 className="title">The fee includes: </h6>
                            <ul>
                                <li>all lunches </li>
                                <li>coffee breaks</li>
                                <li>Transportation / Icebreaking Get-Together/ Conference Dinner.</li>
                            </ul>
                            <br></br>
                            <Link to={process.env.PUBLIC_URL + "/registration-form"}style={{ WebkitTextFillColor:"White"  }}  className="axil-btn btn-fill-white">Register now</Link>
                            
                               </div>
                       
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>
                            </div>
                       
                        
                    </div>
                    
                   
                </div>
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Registration;