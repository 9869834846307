import React from 'react';
import { Link } from 'react-router-dom';
import CounterUp from './CounterUp';


const CounterUpTwo = () => {
    return (
        <div className="section section-padding expert-counterup-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Our Core Values</span>
                            <h2>What Stands Us Out?</h2>
                            <p style={{textAlign: "justify"}}>Esprit was founded in 2003 by three renowned academics who immensely contributed to
shaping and reforming education in Tunisia: both higher education and vocational
training.</p>
<p style={{textAlign: "justify"}}>Esprit has rapidly built a reputation for excellence, thanks to:</p>
   
<ul >
     <li style={{textAlign: "justify"}}>The proximity to the business world helping the institution understand the needs
of companies in terms of problem solving and new technologies.</li>
<li style={{textAlign: "justify"}}>Numerous partnerships with foreign universities allowing knowledge sharing,
exchange programs, and other opportunities.</li>
<li style={{textAlign: "justify"}}>The use of active pedagogy, namely, problem-based project/problem, which
combines theories and hands-on learning.</li>
</ul>
<p style={{textAlign: "justify"}} >The conference will be held in Esprit Campus located in Ariana, Tunisia, in the heart of
the Technopark Ghazala. A</p>
                            <a target='_blank' href="https://www.esprit.tn/esprit/valeurs" className="axil-btn btn-fill-primary">Read more.</a>
                        </div>
                    </div>
                    <div className="col-lg-6" >
                        <div className="row">
                        <img src={process.env.PUBLIC_URL + "/images/cdio/DSC_0307.JPG"} alt="esprit" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CounterUpTwo;