import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>CDIO 2024 | {title} </title>
            <meta name="robots" content="follow" />
            <meta name="description" content="We are delighted to invite you to The 20th CDIO International Conference, which will be held from 10 to 13 June 2024 and will be hosted by the esteemed Esprit School of Engineering.The main theme of the conference is “Engineering Education in the Age of AI." />
            <meta name="keywords" content="inscription cdio 2024 , cdio 2024 , cdio esprit , esprit 2024 , 20th CDIO International Conference, cdio  ,program cdio 2024 ,Engineering Education in the Age of AI , AI education , cdio age of AI"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;