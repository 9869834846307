import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import AboutThree from '../component/about/AboutThree';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import HeaderTwo from '../common/header/HeaderTwo';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';


const EspritVenue = () => {


    return (
        <>
        <SEO title="Esprit" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="ESPRIT"
                page="Esprit School of Engineering"
                />
            <CounterUpTwo />
            <div className="section section-padding bg-color-light">
                <div className="container">
                    {/* <SectionTitle 
                        subtitle="What We Can Do For You"
                        title="Services we can <br> help you with"
                        description=""
                        textAlignment="heading-left"
                        textColor=""
                    /> */}
                    {/* <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area"> */}
                    <div className="container">
                        <CaseStudyProp />
                        
                    {/* </div> */}
                    <br></br>
                    <div className="row">
    
    <iframe className="col-lg-12 col-md-12 col-sm-12" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2862.047543710694!2d10.192812972038938!3d36.9059164383328!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2cb7454c6ed51%3A0x683b3ab5565cd357!2sESPRIT!5e0!3m2!1sen!2stn!4v1680860369273!5m2!1sen!2stn"  width="1200" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
         
  </div>
                </div>
                
                </div>
                
            </div>
            
            {/* <AboutThree /> */}
            
            {/* <CtaLayoutOne /> */}
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default EspritVenue;