import React from 'react';
import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import ReactStars from "react-rating-stars-component";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import { Link } from 'react-router-dom';

const allData = ServiceData;

const Accommodation = () => {
  const strategyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "content-strategy");


    return (
        <>
        <SEO title="Budget accommodation" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Accommodation"
                page="Venue"
                />
         <div className="section section-padding">
                    <div className="container">
                        <div className='row'> 
                    <div className="col-xl-9 col-lg-9"> 
                    <div>Recommended hotels in Tunis (in collaboration with the conference venue). <br/>
These must be booked directly, and will not be available for booking in the conference registration form.</div>
<div className="services-grid service-style-2">
<div className='row'> 
                    <div className="col-xl-3 col-lg-3 col-md-12"> 
					
						<img 
						  src={`${process.env.PUBLIC_URL}/images/hotel/regency-tunis-hotel.jpg`}
						  alt="Regency - room" 
              style={{
               
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', /* Center horizontally */
                flexWrap: 'wrap' ,
                maxWidth: '100%',
               
                borderRadius: '20px'
              }}  
              
					 />
                            
						
						
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12"> 
						<div className="content" style={{marginLeft :"60px"}}>
							<h5 className="title" style={{ marginBottom: "5px" }}> 
								<Link to=""  >Regency Hotel</Link>
							</h5>
              <ReactStars
  value={4} 
    edit={false} // Disable user interaction
    count={5}
    size={24}
    style={{ marginBottom: 0 }}
    activeColor="#ffd700"
  />
							<p style={{ marginBottom: "1px" }}>Gammarth , Tunis <a href='https://www.google.com/maps/d/viewer?mid=1FPDjBDjak7Csi8nwiyswtDt02xo&hl=en&ll=36.939649%2C10.252201999999983&z=17' target='_blank'>show on map</a></p>
              <p>This offer is available for a limited time only (deadline 04/30/24).</p>
              <p>Send Email to this adress <a href='mailto:reservations@regencytunis.com.tn'>reservations@regencytunis.com.tn</a> for booking</p>
							<Link to="" className="more-btn">Visit website</Link>
              
						</div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12"> 
            <div className="content">
							
            	<p style={{ marginTop: "100px" , marginLeft :"50px" ,marginBottom: "5px" ,}}>1 night, 1 adult</p>
              <h5 className="title" style={{ marginBottom: "5px" ,marginLeft :"50px"}}> 
              70 Euro
							</h5>
							<p style={{marginLeft :"50px"}}>Bed and Breakfast</p>
						</div>
            </div>
					</div> 
                     </div>

                     <div className="services-grid service-style-2">
<div className='row'> 
                    <div className="col-xl-3 col-lg-3 col-md-12"> 
				
						<img 
						  src={`${process.env.PUBLIC_URL}/images/hotel/golden-tunis-hotel.jpeg`}
						  alt="Golden Carthage - room" 
              style={{
               
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', /* Center horizontally */
                flexWrap: 'wrap' ,
                maxWidth: '100%',
             
                borderRadius: '20px'
              }}  
              
					 />
                            
						
				</div>
            <div className="col-xl-6 col-lg-6 col-md-12"> 
						<div className="content" style={{marginLeft :"60px"}}>
							<h5 className="title" style={{ marginBottom: "5px" }}> 
								<Link to=""  >Golden Carthage Hotel</Link>
							</h5>
              <ReactStars
  value={5} 
    edit={false} // Disable user interaction
    count={5}
    size={24}
    style={{ marginBottom: 0 }}
    activeColor="#ffd700"
  />
							<p style={{ marginBottom: "1px" }}>Gammarth , Tunis <a href='https://www.google.com/maps/place/GOLDEN+CARTHAGE+TUNIS/@36.9053783,10.3124536,15z/data=!4m2!3m1!1s0x0:0x8658972359bbc8ce?sa=X&ved=2ahUKEwjC46n0s5yEAxU1gP0HHVDbBOoQ_BJ6BAgQEAA' target='_blank'>show on map</a></p>
              <p>This offer is available for a limited time only (deadline 04/30/24).</p>
              <p>Send Email to this adress <a href='mailto:sales.gt3@goldencarthage.com'>sales.gt3@goldencarthage.com</a> for booking</p>
							<Link to="https://www.goldencarthage.com/" target='_blank' className="more-btn">Visit website</Link>
              
						</div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12"> 
            <div className="content" >
							
            	<p style={{ marginTop: "100px" , marginLeft :"50px" ,marginBottom: "5px" ,}}>1 night, 1 adult</p>
              <h5 className="title" style={{ marginBottom: "5px" ,marginLeft :"50px"}}> 
              75 Euro
							</h5>
							<p style={{marginLeft :"50px"}}>Bed and Breakfast</p>
						</div>
            </div>
					</div> 
                     </div>
</div>





<div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>
                        </div> 
                     
                    </div>
            
                </div>
          
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Accommodation;