import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    {/* <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/cdio/original-logo.png"} alt="travel" />
                        </div>
                    </div> */}
                    <div className="col-xl-12 col-lg-12">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Who we are</span>
                            <h6 className="title">The CDIO™ INITIATIVE is an innovative educational framework for producing the next generation of engineers.</h6>
                            <p>The framework provides students with an education stressing engineering fundamentals set in the context of Conceiving — Designing — Implementing — Operating (CDIO) real-world systems and products. Throughout the world, CDIO Initiative collaborators have adopted CDIO as the framework of their curricular planning and outcome-based assessment.</p>
                            <p>The CDIO Initiative was developed with input from academics, industry, engineers, and students and was specifically designed as a template that can be adapted and adopted by any university engineering school. Because CDIO is an open architecture model, it's available to all university engineering programs to adapt to their specific needs and it is being adopted by a growing number of engineering educational institutions around the world. CDIO is currently in use in university aerospace, applied physics, electrical engineering, and mechanical engineering departments.</p>
                            <p>CDIO collaborators (participating universities) recognize that an engineering education is acquired over a long period and in a variety of institutions, and that educators in all parts of this spectrum can learn from practice elsewhere. The CDIO network therefore welcomes members in a diverse range of institutions ranging from research-led internationally acclaimed universities to local colleges dedicated to providing students with their initial grounding in engineering.

CDIO Collaborators regularly develop materials and approaches to share with others. CDIO has open and accessible channels for the program materials and for disseminating and exchanging resources. CDIO collaborators have assembled a unique development team of curriculum, teaching and learning, assessment, design and build, and communications professionals. They are helping others to explore adopting CDIO in their institutions.</p>
                            {/* <Link to="#" className="axil-btn btn-fill-primary btn-large">Read More About CDIO ...</Link> */}
                       <a href='http://cdio.org/about' target='_blank'>Read more.</a>
                        </div>
                        {/* <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="10" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="1500" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Projects delivered so far</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;