import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
  FaMailchimp,
  FaMailBulk,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`} style={{ backgroundColor: "#549C9C" }} >
      <div className="container" >
        <div className="footer-bottom-first">
          <div className="row">
            <div className="footer-social-link">
            <div className="row justify-content-center">
            <div className="row justify-content-center">
  <div className="col-md-2 d-flex align-items-center justify-content-center">
    <Link style={{ color: "white", fontSize: "15px" }} to="/">HOME</Link>
  </div>
  <div className="col-md-2 d-flex align-items-center justify-content-center">
    <Link style={{ color: "white", fontSize: "15px" }} to="/about-cdio">ABOUT</Link>
  </div>
  <div className="col-md-2 d-flex align-items-center justify-content-center">
    <Link style={{ color: "white", fontSize: "15px" }} to="/program">PROGRAMME</Link>
  </div>
  <div className="col-md-2 d-flex align-items-center justify-content-center">
    <Link style={{ color: "white", fontSize: "15px" }} to="/call-for-papers">INFORMATION</Link>
  </div>
  <div className="col-md-2 d-flex align-items-center justify-content-center">
    <Link style={{ color: "white", fontSize: "15px" }} to="/tunisia-venue">VENUE</Link>
  </div>
</div>
</div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
        <div className="row justify-content-center">
          <div className="col-md-4 text-center" style={{ marginRight: "15px" }}>
            <a style={{ color: "white" }} href="mailto:cdio2024secretariat@esprit.tn">
              <FaMailBulk />  cdio2024secretariat@esprit.tn 
            </a> 
          </div>
          <div className="col-md-4 text-center">
         <a style={{ color: "white" }} href="mailto:cdio2024committee@esprit.tn">
              <FaMailBulk />  cdio2024committee@esprit.tn
            </a> 
          </div>
        </div>
        </div>
        <div className="footer-bottom">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <span className="copyright-text" style={{ color: "white" }}>
              © {new Date().getFullYear()}. All rights reserved.
            </span>
            <img src="/images/cdio/logoespritB.png" alt="logo-esprit" style={{ width: "30%", height: "90%" }} />
          </div>
        </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
