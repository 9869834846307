import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SectionTitle from '../elements/section-title/SectionTitle';
import PricingOne from '../component/pricing/PricingOne';
import FaqOne from '../component/faq/FaqOne';
import HeaderTwo from '../common/header/HeaderTwo';
import ProcessOne from '../component/process/ProcessOne';


const PreviousConf = () => {

    return (
        <>
        <SEO title="Previous CDIO Conferences" />
        {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderTwo />
                <BreadCrumbOne 
                title="Previous CDIO Conferences"
                page="About"
                />
                <div className="section section-padding">
                    <div className="container">
                        {/* <SectionTitle 
                            subtitle="CONFERENCE PROGRAM"
                            title="Detailed programme will be available 1 st December 2023"
                            description=""
                            textAlignment="mb-0"
                            textColor=""
                        /> */}
                        <PricingOne />
                        {/* <ProcessOne /> */}
                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                    </ul>
                </div>
                {/* <FaqOne /> */}
                {/* <CtaLayoutOne /> */}
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PreviousConf;