import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
            <li><Link to={process.env.PUBLIC_URL + "/"}>Home</Link></li>
            {/* <li><Link to={process.env.PUBLIC_URL + "/"}></Link></li> */}
            <li className="menu-item-has-children">
                    <Link to="#">About<FaAngleDown /> </Link> 
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/about-cdio"}>CDIO Conference</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/esprit-venue"}>Esprit</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/conference-topics"}>Conference Topics</Link></li>
                      
                        <li><Link to={process.env.PUBLIC_URL + "/previous-conferences"}>Conference history</Link></li>
                      
                        {/* <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        <li><a href="https://new.axilthemes.com/demo/react/abstrak-rtl/">RTL Demo</a></li> */}
                    </ul>
                </li>
            <li className="menu-item-has-children">
                    <Link to="#">Programme <FaAngleDown /> </Link>
                    <ul className="axil-submenu"> 
                        <li><Link to={process.env.PUBLIC_URL + "/program"}>Programme overview</Link></li>
                        <li><Link to="/programme-monday">Parallel sessions Monday</Link></li>
                        <li><Link to="/programme-tuesday">Parallel sessions Tuesday</Link></li>
                        <li><Link to="/programme-wednesday">Parallel sessions Wednesday</Link></li>
                        <li><Link to="/programme-thursday">Parallel sessions Thursday</Link></li>
                        <li><Link to="/panel-thursday">Plenary Panel Thursday</Link></li>
                       
                        <li><Link to="/plenary-keynotes">Plenary keynotes</Link></li>
                        <li><Link to="/working-groups">Working Groups</Link></li>
                       
                        <li><Link to="/social-events">Social Events</Link></li>
                        <li><Link to="/cdio-meetings">CDIO Meetings</Link></li>
                       
                        {/* 
                        <li><Link to="/cdio-meetings">Programme Day 1</Link></li>
                        <li><Link to="/cdio-meetings">Programme Day 2</Link></li>
                        <li><Link to="/cdio-meetings">Programme Day 3</Link></li>
                        <li><Link to="/cdio-meetings">Programme Day 4</Link></li>
                        <li><Link to="/cdio-meetings">Oral presentations</Link></li>
                        <li><Link to="/cdio-meetings">Workshops</Link></li>
                        <li><Link to="/cdio-meetings">Cultural Visit</Link></li>
                        <li><Link to="/cdio-meetings">Conference programme Book</Link></li> */}
                        {/* <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        <li><a href="https://new.axilthemes.com/demo/react/abstrak-rtl/">RTL Demo</a></li> */}
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Information<FaAngleDown /> </Link>
                    <ul className="axil-submenu"> 
                    <li><Link to="/call-for-papers">Information for authors</Link></li>
                        <li><Link to="/important-dates">Important Dates</Link></li>
                        <li><a href="https://www.conftool.pro/cdio2024" target='_blank'>Submission</a></li>
                        <li><Link to={process.env.PUBLIC_URL + "/committees"}>Committees</Link></li>
                        <li><Link to="/practical-information">Practical informations</Link></li>
                        <li><Link to="/conference-app">Conference app</Link></li>
                        <li><Link to="/contact-us">Contact</Link></li>
                        {/* <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        <li><a href="https://new.axilthemes.com/demo/react/abstrak-rtl/">RTL Demo</a></li> */}
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Venue <FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                    <li><Link to={process.env.PUBLIC_URL + "/tunisia-venue"}>Conference venue</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/budget-accommodation"}>Accommodation</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/travel-to-tunisia"}>Transportations</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/visa"}>Visa</Link></li>
                        {/* <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        <li><a href="https://new.axilthemes.com/demo/react/abstrak-rtl/">RTL Demo</a></li> */}
                    </ul>
                </li>
                
                
                {/* <li className="menu-item-has-children">
                    <Link to="#">Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/service-one"}>Service</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-two"}>Service Two</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/design"}>Service Details</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Portfolio <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Two Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-two"}>Three Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-three"}>Four Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-width-one"}>Three Column Width</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-width-two"}>Four Column Width</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-details/plan-management"}>Portfolio Details</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Pages <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/our-office"}>Our Office</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/case-study"}>Case Study</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/case-details/whitehorse"}>Case Study Details</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/team"}>Team</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/team-details/jane-cooper"}>Team Details</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/our-clients"}>Our Clients</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/testimonials"}>Testimonial</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/pricing-table"}>Pricing Table</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/typography"}>Typography</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/404"}>404 Page</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/coming-soon"}>Coming Soon</Link></li>
                    </ul>
                </li> */}
              
                <div className="header-action">
                    <ul className="list-unstyled" style={{marginTop : "8px" }}>
                        <li className="buy-btn"><a target="_blank" rel="noopener noreferrer" href="/registration" className="axil-btn btn-fill-primary">Registration</a></li>
                    </ul>
                    </div>
             
            </ul>
            
        </nav>
        
    )
}

export default Nav;