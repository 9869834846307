// VideoModal.js
import React from 'react';

import {
    Dialog, DialogTitle, DialogContent, DialogActions  } from "@mui/material";

const VideoModal = ({ isOpen, onClose }) => {
  return (
    
    <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="confirmation-dialog-title"
        maxWidth="lg" // Set the maximum width
  fullWidth // Take up the full width of its container
      >
        <DialogContent>
        <iframe width="1150" height="450" src="https://www.youtube.com/embed/1lVrseSXAs0?si=h4A_VXNcdDWLvV9V" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </DialogContent>
        
      </Dialog>
  );
};

export default VideoModal;
