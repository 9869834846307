import React from 'react';
import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import CaseCallForPaper from '../../component/casestudy/CaseCallForPaper';
import SectionTitle from '../../elements/section-title/SectionTitle';


const Committees = () => {


    return (
        <>
        <SEO title="Committees" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Committees"
                page="Information"
                />
            <div className="section section-padding">
                    <div className="container">
                            <div className="row">
                            <div className="col-xl-8 col-lg-8">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            
                            <h6 className="title">International Programme Committee : </h6>
                            <ul style={{paddingTop : "10px"}}>
                            <li><a href='http://cdio.org/cdio-action/people/lamjed-bettaieb' target='_blank'>Lamjed Bettaieb</a>, General Chair, <a href="http://cdio.org/cdio-action/school-profiles/esprit-europe-region" target='_blank'>ESPRIT</a>, Tunisia</li>
                            <li><a href='http://cdio.org/cdio-action/people/reidar-lyng' target='_blank'>Reidar Lyng</a>, <a href='http://cdio.org/cdio-action/school-profiles/ntnu-norwegian-university-science-and-technology' target="_blank"> NTNU - Norwegian University of Science and Technology</a>, Norway</li>
                            <li><a href='http://cdio.org/cdio-action/people/nicoleta-maynard' target='_blank'>Nicoleta Maynard</a>, <a href='http://cdio.org/cdio-action/school-profiles/monash-university' target='_blank'>Monash University</a>, Australia</li>
                          
                            <li><a href='http://cdio.org/cdio-action/people/johan-malmqvist' target='_blank'>Johan Malmqvist</a>, <a href='http://cdio.org/cdio-action/school-profiles/chalmers-university-technology-europe-region' target='_blank'>Chalmers University of Technology</a>, Sweden, editor-in-chief</li>
                          <li>Jens Bennedsen, <a href='http://cdio.org/cdio-action/school-profiles/aarhus-university-co-leader-europe-region'>Aarhus University</a>, Denmark</li>
                          <li> <a href='http://cdio.org/cdio-action/people/kristina' target='_blank'>Kristina Edström </a>,<a href='http://cdio.org/cdio-action/school-profiles/kth-royal-institute-technology-europe-region' target='_blank'>KTH Royal Institute of Technology</a> , Sweden</li>
                           <li>Mark Nivan Singh, <a href='http://cdio.org/cdio-action/school-profiles/singapore-polytechnic-co-leader-asia-region' target='_blank'>Singapore Polytechnic</a>, Sinagpore</li>
                           <li>Nadia Ajailia, <a href="http://cdio.org/cdio-action/school-profiles/esprit-europe-region" target='_blank'>ESPRIT</a>, Tunisia</li>
                          
                          <li>Nabil El Kadhi</li>
                            </ul>
                            <h6 className="title">Local Arrangement Committee:</h6>
<ul style={{paddingTop: "10px"}}>
    <li>Nadia AJAILIA,Committee Chair,  ESPRIT, Tunisia</li>
    <li>Nebgha AYACHI, ESPRIT, Tunisia</li>
    <li>Lamjed BETTAIEB, ESPRIT, Tunisia</li>
    <li>Mariem CHICHTI, ESPRIT, Tunisia</li>
    <li>Chiraz GHARBI, ESPRIT, Tunisia</li>
    <li>Belhassen GUETTAT, ESPRIT, Tunisia</li>
    <li>Oumeima IBN ELFEKIH, ESPRIT, Tunisia</li>
    <li>Syrine KAROUI, ESPRIT, Tunisia</li>
    <li>Fayçal LBABDA, ESPRIT, Tunisia</li>
    <li>Kaouther LOUATI, ESPRIT, Tunisia</li>
    <li>Faten TEBOURBI, ESPRIT, Tunisia</li>
</ul>

                               </div>
                       
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>

                            </div>
                       
                        
                    </div>
                    
                   
                </div>
            
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Committees;