import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';
import CreativeAgency from './pages/CreativeAgency';
import PersonalPortfolio from './pages/PersonalPortfolio';
import HomeStartup from './pages/HomeStartup';
import CorporateAgency from './pages/CorporateAgency';

// Blog Import
import BlogGridView from './pages/BlogGrid';
import BlogCategory from './pages/Category';
import BlogArchive from './pages/Archive';
import BlogDetails from './pages/BlogDetails';

// Service 
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';
import ServiceDetails from './pages/ServiceDetails';

// Project 
import ProjectGridOne from './pages/ProjectGridOne';
import ProjectGridTwo from './pages/ProjectGridTwo';
import ProjectGridThree from './pages/ProjectGridThree';
import ProjectGridFive from './pages/ProjectGridFive';
import ProjectGridFour from './pages/ProjectGridFour';
import ProjectDetails from './pages/ProjectDetails';

// Pages 
import Splash from './pages/Splash';
import AboutUs from './pages/AboutUs';
import OurOffice from './pages/OurOffice';
import OurClients from './pages/OurClients';
import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';
import CaseStudy from './pages/CaseStudy';
import CaseDetails from './pages/CaseStudyDetails';
import Testimonials from './pages/Testimonials';
import PricingTable from './pages/PricingTable';
import Typography from './pages/Typography';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';
import Program from './pages/Program';
import EspritVenue from './pages/EspritVenue';
import TunisiaVenue from './pages/TunisiaVenue';
import CDIO from './pages/CDIO';
import PreviousConf from './pages/PreviousConf';
import CallForPaper from './pages/CallForPaper';
import CallforpaperSite from './pages/Call-for-paper-Site';
import Committees from './pages/information/Committees';
import PracticalInformation from './pages/information/PracticalInformation';
import ContactUs from './pages/information/Contact';
import ConferenceApp from './pages/information/ConferenceApp';
import Travel from './pages/venue/Travel';
import Accommodation from './pages/venue/Accommodation';
import Meetings from './pages/program/Meetings';
import SocialEvents from './pages/program/SocialEvents';
import ConferenceTopics from './pages/ConferenceTopics';
import ImportantDates from './pages/ImportantDates';
import Visa from './pages/venue/Visa';
import Registration from './pages/Registration';
import Registration_Form from './pages/Registration_Form';
import Keynotes from './pages/program/Keynotes';
import WorkingGroups from './pages/program/WorkingGroups';
import ParallelSessionMonday from './pages/program/ParallelSessionMonday';
import ParallelSessionTuesday from './pages/program/ParallelSessionTuesday';
import ParallelSessionThursday from './pages/program/ParallelSessionThursday';
import ParallelSessionWednesday from './pages/program/ParallelSessionWednesday';
import PanelThursday from './pages/program/PanelThursday';
import CDIOMeetings from './pages/program/CDIOMeetings';

const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				{/* <Route path={process.env.PUBLIC_URL + "/"} element={<Splash />}/> */}
				<Route path={process.env.PUBLIC_URL + "/"} element={<CreativeAgency />}/>

				{/* <Route path={process.env.PUBLIC_URL + "/digital-agency"} element={<DigitalAgency />}/>
				{/* <Route path={process.env.PUBLIC_URL + "/creative-agency"} element={<CreativeAgency />}/> */}
				{/* <Route path={process.env.PUBLIC_URL + "/personal-portfolio"} element={<PersonalPortfolio />}/> */}
				{/* <Route path={process.env.PUBLIC_URL + "/home-startup"} element={<HomeStartup />}/> */}
				{/* <Route path={process.env.PUBLIC_URL + "/corporate-agency"} element={<CorporateAgency />}/> */}

				{/* Blogs */}
				{/* <Route path={process.env.PUBLIC_URL + "/blog-grid/"} element={<BlogGridView />}/>
				<Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/> */}

				{/* Service */}
				{/* <Route path={process.env.PUBLIC_URL + "/service-one/"} element={<ServiceOne />}/>
				<Route path={process.env.PUBLIC_URL + "/service-two/"} element={<ServiceTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/> */}

				{/* Project  */}
				{/* <Route path={process.env.PUBLIC_URL + "/project-grid-one"} element={<ProjectGridOne />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-two"} element={<ProjectGridTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-three"} element={<ProjectGridThree />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-one"} element={<ProjectGridFour />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-two"} element={<ProjectGridFive />}/>
				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/> */}

				{/* Pages  */}
				{/* <Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/our-office"} element={<OurOffice />}/>
				<Route path={process.env.PUBLIC_URL + "/our-clients"} element={<OurClients />}/>
				<Route path={process.env.PUBLIC_URL + "/team"} element={<Team />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/case-study"} element={<CaseStudy />}/>
				<Route path={process.env.PUBLIC_URL + "/case-details/:slug"} element={<CaseDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials />}/>
				<Route path={process.env.PUBLIC_URL + "/pricing-table"} element={<PricingTable />}/>
				<Route path={process.env.PUBLIC_URL + "/typography"} element={<Typography />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
				<Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon />}/>
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/> */}
				 <Route path={process.env.PUBLIC_URL + "/service-one/"} element={<ServiceOne />}/> 
 <Route path={process.env.PUBLIC_URL + "/call-for-papers"} element={<CallForPaper />}/>
 <Route path={process.env.PUBLIC_URL + "/committees"} element={<Committees />}/>
 <Route path={process.env.PUBLIC_URL + "/contact-us"} element={<ContactUs />}/>
 <Route path={process.env.PUBLIC_URL + "/travel-to-tunisia"} element={<Travel />}/>
 <Route path={process.env.PUBLIC_URL + "/budget-accommodation"} element={<Accommodation />}/>
 <Route path={process.env.PUBLIC_URL + "/conference-app"} element={<ConferenceApp />}/>
 <Route path={process.env.PUBLIC_URL + "/practical-information"} element={<PracticalInformation />}/>
 <Route path={process.env.PUBLIC_URL + "/call-for-papers-site"} element={<CallforpaperSite />}/>
				<Route path={process.env.PUBLIC_URL + "/about-cdio"} element={<CDIO />}/>
				<Route path={process.env.PUBLIC_URL + "/previous-conferences"} element={<PreviousConf />}/>
				<Route path={process.env.PUBLIC_URL + "/tunisia-venue"} element={<TunisiaVenue />}/>
				<Route path={process.env.PUBLIC_URL + "/esprit-venue"} element={<EspritVenue />}/>
				<Route path={process.env.PUBLIC_URL + "/program"} element={<Program />}/>
				{/* <Route path={process.env.PUBLIC_URL + "/cdio-meetings"} element={<Meetings />}/> */}
				<Route path={process.env.PUBLIC_URL + "/social-events"} element={<SocialEvents />}/>
				{/* <Route path={process.env.PUBLIC_URL + "/test"} element={<Contact />}/> */}
				<Route path={process.env.PUBLIC_URL + "/conference-topics"} element={<ConferenceTopics />}/>
				<Route path={process.env.PUBLIC_URL + "/important-dates"} element={<ImportantDates />}/>
				<Route path={process.env.PUBLIC_URL + "/visa"} element={<Visa />}/>
				<Route path={process.env.PUBLIC_URL + "/registration"} element={<Registration />}/>
				<Route path={process.env.PUBLIC_URL + "/registration-form"} element={<Registration_Form />}/>
				<Route path={process.env.PUBLIC_URL + "/plenary-keynotes"} element={<Keynotes />}/>
				<Route path={process.env.PUBLIC_URL + "/working-groups"} element={<WorkingGroups />}/>
				<Route path={process.env.PUBLIC_URL + "/programme-monday"} element={<ParallelSessionMonday />}/>
				<Route path={process.env.PUBLIC_URL + "/programme-tuesday"} element={<ParallelSessionTuesday />}/>
				<Route path={process.env.PUBLIC_URL + "/programme-wednesday"} element={<ParallelSessionWednesday />}/>
				<Route path={process.env.PUBLIC_URL + "/programme-thursday"} element={<ParallelSessionThursday />}/>
				<Route path={process.env.PUBLIC_URL + "/panel-thursday"} element={<PanelThursday />}/>
				<Route path={process.env.PUBLIC_URL + "/cdio-meetings"} element={<CDIOMeetings />}/>
				<Route path={process.env.PUBLIC_URL + "/conference-app"} element={<ConferenceApp />}/>
				{/* <Route path={process.env.PUBLIC_URL + "/programme-monday-testing"} element={<ParallelSessionMondayTest />}/>
				<Route path={process.env.PUBLIC_URL + "/programme-tuesday-testing"} element={<ParallelSessionTuesdayTest />}/>
				<Route path={process.env.PUBLIC_URL + "/programme-wednesday-testing"} element={<ParallelSessionWednesdayTest />}/>
				<Route path={process.env.PUBLIC_URL + "/programme-thursday-testing"} element={<ParallelSessionThursdayTest />}/> */}
				{/* <Route path={process.env.PUBLIC_URL + "/panel-thursday-testing"} element={<PanelThursdayTest />}/> */}
				{/* <Route path={process.env.PUBLIC_URL + "/plenary-keynotes-testing"} element={<KeynotesTest />}/> */}
				{/* <Route path={process.env.PUBLIC_URL + "/team"} element={<Team />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-two"} element={<ProjectGridTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-three"} element={<ProjectGridThree />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-one"} element={<ProjectGridFour />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-two"} element={<ProjectGridFive />}/> */}
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
