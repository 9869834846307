import React from "react";

import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import CaseCallForPaper from "../../component/casestudy/CaseCallForPaper";
import HeaderTwo from "../../common/header/HeaderTwo";
import BreadCrumbOne from "../../elements/breadcrumb/BreadCrumbOne";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const CDIOMeetings = () => {
  return (
    <>
      <SEO title="CDIO Meetings" />

      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderTwo />
        {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
        <BreadCrumbOne title="CDIO Meetings" page="Programme" />
        <div className="section section-padding expert-counterup-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-heading heading-left">
                  {/* <span className="subtitle">September 2023</span> */}
                  {/* <h2>What makes us special?</h2> */}
                 
                  <div className="row">
                    <div className="col-lg-8">
                    <h4>Monday</h4>
                      <p className="mb--50">
                        15:00–16:00 CDIO Council Meeting <br />
                        <span style={{color:"#549C9C"}}>Meeting Room Fourth Floor</span>
                      </p>
                      <h4>Wednesday</h4>
                      <p className="mb--50">
                      16:30–17:30 CDIO Regional Meetings <br /> 
                      <strong>Europe</strong> <br />
                      

<span style={{color:"#549C9C"}}>Room D 0-1</span> <br/>
<strong>Asia</strong> <br />
                      

                      <span style={{color:"#549C9C"}}>Room D 1-1</span>
                      <br/>
<strong>UK-Ireland</strong> <br />
                      

                      <span style={{color:"#549C9C"}}>Room A 0-1</span>
                      <br/>
<strong>Australia</strong> <br />
                      

                      <span style={{color:"#549C9C"}}>Room A 0-2</span>
                      <br/>
<strong>Africa</strong> <br />
                      

                      <span style={{color:"#549C9C"}}>Room A 0-3</span>
                      <br/>
<strong>North America</strong> <br />
                      

                      <span style={{color:"#549C9C"}}>Room A 0-4</span>
                      <br/>
<strong>South America</strong> <br />
                      

                      <span style={{color:"#549C9C"}}>Room A 0-5</span>
 </p>
 <h4>Thursday</h4>
                      <p className="mb--50">
                        16:00–17:30 CDIO council meeting <br />
                        <span style={{color:"#549C9C"}}>Meeting Room Fourth Floor</span>
                      </p>
                    </div>
                    <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default CDIOMeetings;
