import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BannerTwo from '../component/banner/BannerTwo';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';



const CreativeAgency = () => {
    const dateListPaper = [
        { date: new Date(2023, 10, 15), text: 'Abstract submission Deadline' },
        { date: new Date(2023, 11, 1), text: 'Notification of acceptance' },
        { date: new Date(2024, 0, 15), text: 'Draft paper submission Deadline' },
        { date: new Date(2024, 2, 7), text: 'Notification of acceptance' },
        { date: new Date(2024, 3, 7), text: 'Final submission and registration' },
        // Add more dates as needed
      ];
      const dateListWorkshop = [
        { date: new Date(2023, 10, 15), text: 'Outline submission deadline' },
        { date: new Date(2023, 11, 1), text: 'Notification of acceptance' },
        { date: new Date(2024, 0, 15), text: 'Draft paper submission Deadline' },
        { date: new Date(2024, 2, 7), text: 'Notification of acceptance' },
        { date: new Date(2024, 3, 7), text: 'Final submission and registration' },
        // Add more dates as needed
      ];
      const dateListGroups = [
        { date: new Date(2023, 10, 15), text: 'Outline submission deadline' },
        { date: new Date(2023, 11, 1), text: 'Notification of acceptance' },
        { date: new Date(2024, 0, 15), text: 'Activity Description Submission deadline' },
        { date: new Date(2024, 3, 28), text: 'Sign-up deadline' },
        { date: new Date(2024, 4, 7), text: 'Member Acceptance' },
        // Add more dates as needed
      ];
      const currentDate = new Date();
    return (
        <>
        <SEO title="Home - TUNISIA"/>
        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
          
            <BannerTwo />
            <BlogOne />
            <div className="section">
                <div className="container">
                <SectionTitle 
                    // subtitle="What We Can Do For You"
                    title="Important Dates"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                  <div className='row'>
                  <div className="col-xl-4 col-lg-4" align="center"><h4>Papers</h4>
                        <Timeline >
                        {dateListPaper.map((item, index) => {
  // Find the next upcoming date
  const isNextDate = index === dateListPaper.findIndex(dateItem => currentDate <= dateItem.date);

  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent color="textSecondary">
        {item.date.toDateString()}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {/* Set color to 'success' if item.date is the next upcoming date, otherwise 'grey' */}
        {/* <TimelineDot color={isNextDate ? 'success' : 'grey'} /> */}
        <TimelineDot style={{ backgroundColor: isNextDate ? '#689107' : '#808080' }} />
       
        {index < dateListPaper.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ textDecoration: currentDate > item.date ? 'line-through' : 'none' }}>
        {item.text}
      </TimelineContent>
    </TimelineItem>
  );
})}
    </Timeline></div>
                  <div className="col-xl-4 col-lg-4" align="center"> <h4 >Workshops & Roundtables </h4>
    <Timeline  >
    {dateListWorkshop.map((item, index) => {
  // Find the next upcoming date
  const isNextDate = index === dateListWorkshop.findIndex(dateItem => currentDate <= dateItem.date);

  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent color="textSecondary">
        {item.date.toDateString()}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {/* Set color to 'success' if item.date is the next upcoming date, otherwise 'grey' */}
        {/* <TimelineDot color={isNextDate ? 'success' : 'grey'} /> */}
        <TimelineDot style={{ backgroundColor: isNextDate ? '#c90484' : '#808080' }} />
      
        {index < dateListWorkshop.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ textDecoration: currentDate > item.date ? 'line-through' : 'none' }}>
        {item.text}
      </TimelineContent>
    </TimelineItem>
  );
})}

    </Timeline></div>
                  <div className="col-xl-4 col-lg-4" align="center"> <h4>Working Groups </h4>
    <Timeline>
    {dateListGroups.map((item, index) => {
  // Find the next upcoming date
  const isNextDate = index === dateListGroups.findIndex(dateItem => currentDate <= dateItem.date);

  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent color="textSecondary">
        {item.date.toDateString()}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {/* Set color to 'success' if item.date is the next upcoming date, otherwise 'grey' */}
        {/* <TimelineDot color={isNextDate ? 'success' : 'grey'} /> */}
        <TimelineDot style={{ backgroundColor: isNextDate ? '#faa91e' : '#808080' }} />
      
       
        {index < dateListGroups.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ textDecoration: currentDate > item.date ? 'line-through' : 'none' }}>
        {item.text}
      </TimelineContent>
    </TimelineItem>
  );
})}

     
    </Timeline></div>
                  </div>
                </div>
               
            
            </div>
            <div className="section">
                {/* <div className="container">
                <SectionTitle 
                    // subtitle="What We Can Do For You"
                    title="The program is available"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                  
                </div> */}
               
              {/* <p align="center">We are looking forward to seeing you at the conference.</p> */}
            </div>
           
            {/* <ProjectOne parentClass="bg-color-light"/> */}
            {/* <CounterUpOne />
            <TestimonialOne />
            <BrandOne /> */}
            
            
            {/* <CtaLayoutOne /> */}
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default CreativeAgency;

