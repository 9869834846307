import React from "react";
import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import BreadCrumbOne from "../../elements/breadcrumb/BreadCrumbOne";
import CaseCallForPaper from "../../component/casestudy/CaseCallForPaper";
import SectionTitle from "../../elements/section-title/SectionTitle";
import { Table } from "react-bootstrap";

const Visa = () => {
  return (
    <>
      <SEO title="Visa" />

      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderTwo />
        {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
        <BreadCrumbOne title="Visa" page="Venue" />
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8">
                <div className="section-heading heading-left">
                  <p>
                    {" "}
                    On this page we present to you the list of countries without
                    and with visa for Tunisian nationals.{" "}
                  </p>
                </div>

                <Table striped bordered hover>
                  <thead align="center">
                    <tr>
                      <th>COUNTRY</th>
                      <th>Entry Visa to Tunisia</th>
                    </tr>
                  </thead>
                  <tbody class="row-hover" align="center">
                    <tr className="row-2 even">
                      <td className="column-1">
                        <font>
                          <font>Afghanistan</font>
                        </font>
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-3 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>South Africa</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-4 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Albania</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-5 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Algeria</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-6 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Germany</font>
                          </font>
                     
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-7 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Andorra</font>
                          </font>
                       
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-8 even">
                      <td className="column-1">
                        <font>
                          <font>Angola</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-9 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Antigua and Barbuda</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-10 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Netherlands Antilles</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-11 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Saudi Arabia</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-12 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Argentina</font>
                          </font>
                     
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-13 odd">
                      <td className="column-1">
                        <font>
                          <font>Armenia</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-14 even">
                      <td className="column-1">
                        <font>
                          <font>Australia</font>
                        </font>
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-15 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>Austria</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-16 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Azerbaijan</font>
                          </font>
                      
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-17 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Bahamas</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-18 even">
                      <td className="column-1">
                        <font>
                          <font>Bahrain</font>
                        </font>
                      </td>
                      
                      <td >
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-19 odd">
                      <td className="column-1">
                        <font>
                          <font>Bangladesh</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-20 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Barbados</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-21 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Belize</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-22 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Belgium</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-23 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Benign</font>
                          </font>
                      
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-24 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Bermuda</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-25 odd">
                      <td className="column-1">
                        <font>
                          <font>Belarus</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-26 even">
                      <td className="column-1">
                        <font>
                          <font>Bolivia</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-27 odd">
                      <td className="column-1">
                        <font>
                          <font>Bosnia - Herzegovina</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-28 even">
                      <td className="column-1">
                        <font>
                          <font>Botswana</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-29 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Brazil</font>
                          </font>
                     
                      </td>
                 
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-30 even">
                      <td className="column-1">
                        <font>
                          <font>Brunei</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-31 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>Bulgaria</font>
                          </font>
                       
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-32 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Burkina Faso</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-33 odd">
                      <td className="column-1">
                        <font>
                          <font>Burundi</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-34 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Cambodia</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-35 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Cameroon</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-36 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Canada</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-37 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Green cap</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-38 even">
                      <td className="column-1">
                        <font>
                          <font>Central Africa</font>
                        </font>
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-39 odd">
                      <td className="column-1">
                        <font>
                          <font>Chile</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-40 even">
                      <td className="column-1">
                       
                          <font>
                            <font>China</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-41 odd">
                      <td className="column-1">
                        <font>
                          <font>Cyprus</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-42 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Colombia</font>
                          </font>
               
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-43 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Comoros</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-44 even">
                      <td className="column-1">
                        <font>
                          <font>Congo - Kinshasa (Dem. Rep. of)</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-45 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>Congo - Brazzaville (Rep. of)</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-46 even">
                      <td className="column-1">
                        <font>
                          <font>North Korea</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-47 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>South Korea</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-48 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Costa Rica</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-49 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Ivory Coast</font>
                          </font>
                   
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-50 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Croatia</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-51 odd">
                    <td className="column-1">
                          <font>
                            <font>Cuba</font>
                          </font>
                     
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-52 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Denmark</font>
                          </font>
                       
                      </td>
                   
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-53 odd">
                    <td className="column-1">
                      
                          <font>
                            <font>Djibouti</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-54 even">
                    <td className="column-1">
                      
                          <font>
                            <font>Dominica (Island)</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-55 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Egypt</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-56 even">
                      <td className="column-1">
                       
                          <font>
                            <font>United Arab Emirates</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-57 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Ecuador</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-58 even">
                      <td className="column-1">
                        <font>
                          <font>Eritrea</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-59 odd">
                      <td className="column-1">
                     
                          <font>
                            <font>Spain</font>
                          </font>
                      
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-60 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Estonia</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-61 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>United States of America</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-62 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Ethiopia</font>
                          </font>
                     
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-63 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Fiji (Islands)</font>
                          </font>
                  
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-64 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Finland</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-65 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>France</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-66 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Gabon</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-67 odd">
                      <td className="column-1">
                        <font>
                          <font>Gambia</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-68 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Georgia</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-69 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Ghana</font>
                          </font>
                       
                      </td>
                   
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-70 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Greece</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-71 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Grenade</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-72 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Guatemala</font>
                          </font>
                     
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-73 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Guinea Conakry</font>
                          </font>
                 
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-74 even">
                      <td className="column-1">
                        <font>
                          <font>Guinea - Bissau</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-75 odd">
                      <td className="column-1">
                        <font>
                          <font>Equatorial Guinea</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-76 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Guyana</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-77 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Haiti</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-78 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Honduras</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-79 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Hong Kong</font>
                          </font>
                      
                      </td>
                   
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-80 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Hungary</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-81 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>India</font>
                          </font>
                      
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-82 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Indonesia</font>
                          </font>
                        
                      </td>
                  
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-83 odd">
                      <td className="column-1">
                        <font>
                          <font>Iraq</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-84 even">
                      <td className="column-1">
                        <font>
                          <font>Iran</font>
                        </font>
                      </td>
                   
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-85 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Ireland</font>
                          </font>
                      
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-86 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Iceland</font>
                          </font>
                    
                      </td>
                  
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-87 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Italy</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-88 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Jamaica</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-89 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Japan</font>
                          </font>
                     
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-90 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Jordan</font>
                          </font>
                     
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-91 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>Kazakhstan</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-92 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Kenya</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-93 odd">
                      <td className="column-1">
                        <font>
                          <font>Kyrgyzstan</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-94 even">
                      <td className="column-1">
                        <font>
                          <font>Kiribati (Rep. of)</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-95 odd">
                      <td className="column-1">
                        <font>
                          <font>Kuwait</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-96 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Laos</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-97 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Latvia</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-98 even">
                      <td className="column-1">
                        <font>
                          <font>Lesotho (Rep. of)</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-99 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>LebaNO</font>
                          </font>
                      
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-100 even">
                      <td className="column-1">
                        <font>
                          <font>Liberia</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-101 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Libya</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-102 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Liechtenstein</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-103 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>Lithuania</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-104 even">
                      <td className="column-1">
                        <font>
                            <font>Luxembourg</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-105 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Macedonia</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-106 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Madagascar</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-107 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>Malaysia</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-108 even">
                      <td className="column-1">
                        <font>
                          <font>Maldives (Islands)</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-109 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Mali</font>
                          </font>
                    
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-110 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Malta</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-111 odd">
                      <td className="column-1">
                      
                          <font>
                            <font>Morocco</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-112 even">
                      <td className="column-1">
                        <font>
                          <font>Mauritius (Island)</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-113 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Mauritania</font>
                          </font>
                     
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-114 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Mexico</font>
                          </font>
                        
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-115 odd">
                      <td className="column-1">
                        <font>
                          <font>Moldova</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-116 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Monaco</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-117 odd">
                      <td className="column-1">
                        <font>
                          <font>Mongolia</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-118 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Montenegro</font>
                          </font>
                      
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-119 odd">
                      <td className="column-1">
                        <font>
                          <font>Mozambique</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-120 even">
                      <td className="column-1">
                        <font>
                          <font>Myanmar (Burma)</font>
                        </font>
                      </td>
                   
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-121 odd">
                      <td className="column-1">
                        <font>
                          <font>Namibia</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-122 even">
                      <td className="column-1">
                        <font>
                          <font>Nepal</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-123 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Nicaragua</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-124 even">
                      <td className="column-1">
                        <font>
                          <font>Niger</font>
                        </font>
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-125 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Nigeria</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-126 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Norway</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-127 odd">
                      <td className="column-1">
                        <font>
                          <font>New Zealand</font>
                        </font>
                      </td>
                   
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-128 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Oman (Sultanate of)</font>
                          </font>
                       
                      </td>
                   
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-129 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Uganda</font>
                          </font>
                     
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-130 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Uzbekistan</font>
                          </font>
                        
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-131 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Pakistan</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-132 even">
                      <td className="column-1">
                        <font>
                          <font>Palestine</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-133 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Panama</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-134 even">
                      <td className="column-1">
                        <font>
                          <font>Paraguay</font>
                        </font>
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-135 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>The Netherlands</font>
                          </font>
                     
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-136 even">
                      <td className="column-1">
                        
                          <font>
                            <font>Peru</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-137 odd">
                      <td className="column-1">
                       -
                          <font>
                            <font>Philippines</font>
                          </font>
                        
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-138 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Poland</font>
                          </font>
                    
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-139 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Portugal</font>
                          </font>
                      
                      </td>
                      
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-140 even">
                      <td className="column-1">
                      
                          <font>
                            <font>Qatar</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-141 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>Dominican Republic</font>
                          </font>
                       
                      </td>
                    
                      <td className="column-3"></td>
                    </tr>
                    <tr className="row-142 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Czech Republic</font>
                          </font>
                    
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-143 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Romania</font>
                          </font>
                  
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-144 even">
                      <td className="column-1">
                       
                          <font>
                            <font>United Kingdom</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-145 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Russia</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-146 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Rwanda</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-147 odd">
                      <td className="column-1">
                        
                          <font>
                            <font>Saint Kitts and Nevis</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-148 even">
                      <td className="column-1">
                        
                          <font>
                            <font>St. LUCIA</font>
                          </font>
                       
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-149 odd">
                      <td className="column-1">
                       
                          <font>
                            <font>San Marino</font>
                          </font>
                      
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-150 even">
                      <td className="column-1">
                       
                          <font>
                            <font>Saint Vincent and the Grenadines</font>
                          </font>
                      
                      </td>
                    
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-151 odd">
                      <td className="column-1">
                        <font>
                          <font>Solomon (Islands)</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>NO</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-152 even">
                      <td className="column-1">
                        <font>
                          <font>El Salvador</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-153 odd">
                      <td className="column-1">
                        <font>
                          <font>Sao Tome and Principe</font>
                        </font>
                      </td>
                     
                      <td className="column-3">
                        <font>
                          <font>YES</font>
                        </font>
                      </td>
                    </tr>
                    <tr className="row-154 even">
                      <td className="column-1">
                       
                          Senegal
                     
                      </td>
                   
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-155 odd">
                      <td className="column-1">
                        
                      Serbia
                       
                      </td>
                    
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-156 even">
                      <td className="column-1">
                       
                      Seychelles Islands
                       
                      </td>
                     
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-157 odd">
                      <td className="column-1">Sierra Leone</td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-158 even">
                      <td className="column-1">Singapore</td>
                  
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-159 odd">
                      <td className="column-1">
                       
                      Slovakia
                      
                      </td>
                     
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-160 even">
                      <td className="column-1">
                       
                      Slovenia
                    
                      </td>
                   
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-161 odd">
                      <td className="column-1">Somalia</td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-162 even">
                      <td className="column-1">
                        
                      Sudan
                        
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-163 odd">
                      <td className="column-1">
                        
                      Sri Lanka
                      
                      </td>
                   
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-164 even">
                      <td className="column-1">
                        
                      Sweden
                      
                      </td>
                     
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-165 odd">
                      <td className="column-1">
                       
                      Switzerland
                      
                      </td>
                   
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-166 even">
                      <td className="column-1">
                        
                      Suriname
                        
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-167 odd">
                      <td className="column-1">Syria</td>
                      
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-168 even">
                      <td className="column-1">
                       
                      Tajikistan
                        
                      </td>
                    
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-169 odd">
                      <td className="column-1">Taiwan</td>
                   
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-170 even">
                      <td className="column-1">
                       
                      Tanzania
                      
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-171 odd">
                      <td className="column-1">
                        
                      Chad
                       
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-172 even">
                      <td className="column-1">
                        
                      Thailand
                    
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-173 odd">
                      <td className="column-1">Togo</td>
                    
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-174 even">
                      <td className="column-1">
                       
                      Trinidad and Tobago
                    
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-175 odd">
                      <td className="column-1">Turkmenistan</td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-176 even">
                      <td className="column-1">
                       
                      Turkey
                      
                      </td>
                     
                      <td className="column-3">NO</td>
                    </tr>
                    <tr className="row-177 odd">
                      <td className="column-1">
                       
                      Ukraine
                      
                      </td>
                    
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-178 even">
                      <td className="column-1">Uruguay</td>
                      
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-179 odd">
                      <td className="column-1">
                       
                      Venezuela
                       
                      </td>
                    
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-180 even">
                      <td className="column-1">
                        
                          Vietnam
                      
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-181 odd">
                      <td className="column-1">Yemen</td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-182 even">
                      <td className="column-1">
                        
                      Zambia
                    
                      </td>
                     
                      <td className="column-3">YES</td>
                    </tr>
                    <tr className="row-183 odd">
                      <td className="column-1">Zimbabwe</td>
                    
                      <td className="column-3">YES</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>
            </div>
          </div>
        </div>

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Visa;
