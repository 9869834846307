import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import SEO from '../common/SEO';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import HeaderTwo from '../common/header/HeaderTwo';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import CaseCallForPaper from '../component/casestudy/CaseCallForPaper';


const CallForPaper = () => {


    return (
        <>
        <SEO title="Call For Papers" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Call For Papers"
                page="Information for authors

                "
                />
            <CaseCallForPaper />
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default CallForPaper;