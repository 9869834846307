import React from 'react';
import FooterOne from '../common/footer/FooterOne';
// import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import HeaderTwo from '../common/header/HeaderTwo';
import { FaCode, FaCompress, FaGlobe } from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import BannerFive from '../component/banner/BannerFive';
const TunisiaVenue = () => {

    return (
        <>
        <SEO title="Tunisia" />
        {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderTwo />
                <BannerFive />
                 <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                      
                        <div className="col-lg-12">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    {/* <h3 className="title">We delivered</h3> */}
                                    <p>Did you know that Tunisia has been one of the major centers of Mediterranean culture for 3,000 years? The heritage of ancient Carthage combines history, sensations, aromas and flavors to amaze you. From the souks to the beaches, monuments and fine dining, everything here is traditional, relaxed, harmonious and classy.</p>
                                    <p style={{ margin: '0', padding: '0'}}> <strong>Where to go or discover tunisia or getting around:</strong> <a href=' https://www.tripadvisor.com/Attractions-g293753-Activities-Tunisia.html' target='_blank'>THE 15 BEST Things to Do in Tunisia - 2024 </a> , <a href='https://tunisiatourism.info/en/destination/tunis' target='_blank'>Tunis and the Coasts of Carthage</a></p>
                             
                                    <p style={{ margin: '0', padding: '0'}}> <strong>What you should try and where to eat  or taste of Tunisia:</strong> <a href='https://wanderlog.com/list/geoCategory/205435/where-to-eat-best-restaurants-in-tunis' target='_blank'>Where to eat: the 50 best restaurants in Tunis
</a> </p>
                                  
                                  
                                    </div>
                                
                                {/* <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaCompress /> Strategy</Accordion.Header>
                                        <Accordion.Body>
                                        Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaCode /> Design</Accordion.Header>
                                        <Accordion.Body>
                                        Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                                        <Accordion.Body>
                                        Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion> */}


                            </div>
                        </div>
                       
                {/* <SplashBanner /> */}
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
  <figure style={{ display: "inline-block", width: "100%" }}>
    <img src={process.env.PUBLIC_URL + "/images/cdio/tunisia-jam.jpg"} alt="tunisia" style={{ width: "100%" }} />
    <figcaption
      style={{
        width: "100%",
        backgroundColor: "rgb(84,156,156)",
        color: "#fff",
        padding: "7px",
        fontSize: "14px",
        boxSizing: "border-box",
      }}
    >
      Historical and cultural sites
    </figcaption>
  </figure>
  <p style={{ textAlign: "justify", maxWidth: "100%", alignSelf: "flex-start" }}>
    Tunisia is renowned for its abundant historical and cultural legacy, with the ancient city of Carthage standing as a UNESCO World Heritage site and an essential destination for history enthusiasts. Within its ruins lie remnants of the ancient civilization, such as the Roman Theatre, Antonine Baths, and the Carthage National Museum. Notable historical sites also include the impressive Amphitheater of El Jem, the captivating Medina of Tunis (another UNESCO site), and the archaeological site of Dougga.
  </p>
</div>
                            <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                        
                        <figure  style={{ display: "inline-block", width: "100%" }}>
                        <img src={process.env.PUBLIC_URL + "/images/cdio/tunisia-sahara.jpg"} alt="tunisia" style={{ width: "100%" }}/>

            
              <figcaption
                style={{
                  width: "100%",
                  backgroundColor: "rgb(84,156,156)",
                  color: "#fff",
                  padding: "7px",
                  fontSize: "14px",
                  boxSizing: "border-box",
                 
                }}
              >
          Desert adventures
              </figcaption>
            
          </figure>
          <p style={{ textAlign: "justify", maxWidth: "100%", alignSelf: "flex-start" }}> The vast Sahara Desert in southern Tunisia offers unique experiences for adventurous travelers. Excursions into the desert, such as those from Tozeur or Douz, allow visitors to ride camels, explore the mesmerizing sand dunes, and spend nights under the starry desert sky in traditional Bedouin camps.
                                       </p>
                   
                            </div>
                            </div>
                            <div className="row">
                        <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                    
                        <figure  style={{ display: "inline-block", width: "100%" }}>
                        <img src={process.env.PUBLIC_URL + "/images/cdio/tunisia-page.jpg"} alt="tunisia" style={{ width: "100%" }} />

            
              <figcaption
                style={{
                  width: "100%",
        backgroundColor: "rgb(84,156,156)",
        color: "#fff",
        padding: "7px",
        fontSize: "14px",
        boxSizing: "border-box",
                 
                }}
              >
            Beaches and coastline
              </figcaption>
            
          </figure>
         <p style={{ textAlign: "justify", maxWidth: "100%", alignSelf: "flex-start" }}>                                       
Tunisia's picturesque coastline along the Mediterranean Sea captivates visitors with its stunning beaches, providing a diverse range of options. Hammamet, Sousse, and Djerba are among the favored coastal destinations, where one can bask in the sun, indulge in water sports, and unwind on sandy shores. With crystal-clear waters and picturesque landscapes, Tunisia's beaches hold immense appeal for tourists, drawing them in as a prominent attraction.
</p>
                   
                   
                            </div>
                            <div className="col-lg-6" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                      
                        <figure  style={{ display: "inline-block", width: "100%" }}>
                        <img src={process.env.PUBLIC_URL + "/images/cdio/Tunisia-aindrahem.jpg"} alt="tunisia" style={{ width: "100%" }}/>

            
              <figcaption
                style={{
                  width: "100%",
                  backgroundColor: "rgb(84,156,156)",
                  color: "#fff",
                  padding: "7px",
                  fontSize: "14px",
                  boxSizing: "border-box",
                
                }}
              >
              Forests and natural escapes
              </figcaption>
            
          </figure>
                        <p style={{ textAlign: "justify", maxWidth: "100%", alignSelf: "flex-start" }}>Tunisia's Tell Atlas hosts diverse natural forests with Mediterranean-adapted flora like evergreen and cork oaks. These ecosystems face threats from deforestation and climate change, emphasizing the need for sustainable management. Local communities, historically reliant on forest resources, especially cork, highlight the delicate balance between human needs and environmental preservation. Ongoing conservation efforts aim to safeguard Tunisia's unique natural forests.</p>
                    </div>
                            </div>
                            </div>
                       
                                 
            </section>
           
            <br></br><br></br>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TunisiaVenue;