import React, { useState } from 'react';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import FooterOne from '../common/footer/FooterOne';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

const textStyle = {
  fontSize: '1.2rem', // Adjust the font size as needed
};

const ImportantDates = () => {
  const h4Style = {
    display: 'flex',
    alignItems: 'center',
  };

  const lineStyle = {
    flexGrow: 1,
    height: '1px', /* Épaisseur de la ligne */
    backgroundColor: 'grey', /* Couleur de la ligne, vous pouvez la personnaliser */
    marginLeft: '10px', /* Marge entre le texte et la ligne */
  };
 // Sample list of dates
 const dateListPaper = [
  { date: new Date(2023, 10, 15), text: 'Abstract submission Deadline' },
  { date: new Date(2023, 11, 1), text: 'Notification of acceptance' },
  { date: new Date(2024, 0, 15), text: 'Draft paper submission Deadline' },
  { date: new Date(2024, 2, 7), text: 'Notification of acceptance' },
  { date: new Date(2024, 3, 7), text: 'Final submission and registration' },
  // Add more dates as needed
];
const dateListWorkshop = [
  { date: new Date(2023, 10, 15), text: 'Outline submission deadline' },
  { date: new Date(2023, 11, 1), text: 'Notification of acceptance' },
  { date: new Date(2024, 0, 15), text: 'Draft paper submission Deadline' },
  { date: new Date(2024, 2, 7), text: 'Notification of acceptance' },
  { date: new Date(2024, 3, 7), text: 'Final submission and registration' },
  // Add more dates as needed
];
const dateListGroups = [
  { date: new Date(2023, 10, 15), text: 'Outline submission deadline' },
  { date: new Date(2023, 11, 1), text: 'Notification of acceptance' },
  { date: new Date(2024, 0, 15), text: 'Activity Description Submission deadline' },
  { date: new Date(2024, 3, 28), text: 'Sign-up deadline' },
  { date: new Date(2024, 4, 7), text: 'Member Acceptance' },
  // Add more dates as needed
];
const currentDate = new Date();

    return (
        <>
        <SEO title="Important Dates" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Important Dates"
                page="Information"
                />
            <div className="section section-padding">
                    <div className="container">
                        
                       <div className='row'>
                       <div className="col-xl-8 col-l-8">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                        <h4 style={h4Style}>Papers<span style={lineStyle}></span></h4>
                        <Timeline  >
                        {dateListPaper.map((item, index) => {
  // Find the next upcoming date
  const isNextDate = index === dateListPaper.findIndex(dateItem => currentDate <= dateItem.date);

  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent color="textSecondary">
        {item.date.toDateString()}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {/* Set color to 'success' if item.date is the next upcoming date, otherwise 'grey' */}
        {/* <TimelineDot color={isNextDate ? 'success' : 'grey'} /> */}
        <TimelineDot style={{ backgroundColor: isNextDate ? '#689107' : '#808080' }} />
        {index < dateListPaper.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ textDecoration: currentDate > item.date ? 'line-through' : 'none' }}>
        {item.text}
      </TimelineContent>
    </TimelineItem>
  );
})}
    </Timeline>
    <h4 style={h4Style}>Workshops & Roundtables<span style={lineStyle}></span></h4>
   
    <Timeline  >
    {dateListWorkshop.map((item, index) => {
  // Find the next upcoming date
  const isNextDate = index === dateListWorkshop.findIndex(dateItem => currentDate <= dateItem.date);

  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent color="textSecondary">
        {item.date.toDateString()}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {/* Set color to 'success' if item.date is the next upcoming date, otherwise 'grey' */}
        {/* <TimelineDot color={isNextDate ? 'success' : 'grey'} /> */}
        <TimelineDot style={{ backgroundColor: isNextDate ? '#c90484' : '#808080' }} />
      
        {index < dateListWorkshop.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ textDecoration: currentDate > item.date ? 'line-through' : 'none' }}>
        {item.text}
      </TimelineContent>
    </TimelineItem>
  );
})}
    </Timeline>
    <h4 style={h4Style}>Working Groups<span style={lineStyle}></span></h4>
   
    
    <Timeline  >
    {dateListGroups.map((item, index) => {
  // Find the next upcoming date
  const isNextDate = index === dateListGroups.findIndex(dateItem => currentDate <= dateItem.date);

  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent color="textSecondary">
        {item.date.toDateString()}
      </TimelineOppositeContent>
      <TimelineSeparator>
        {/* Set color to 'success' if item.date is the next upcoming date, otherwise 'grey' */}
        {/* <TimelineDot color={isNextDate ? 'success' : 'grey'} /> */}
        <TimelineDot style={{ backgroundColor: isNextDate ? '#faa91e' : '#808080' }} />
      
        {index < dateListGroups.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ textDecoration: currentDate > item.date ? 'line-through' : 'none' }}>
        {item.text}
      </TimelineContent>
    </TimelineItem>
  );
})}
    </Timeline>
                            </div>
                       
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
      alt="stb logo"
      style={{
        display: "block",
        margin: "20px auto 0",
        maxWidth: "80%",
        height: "auto",
      }}
    />
  </div>
</div>
                       </div>
                        
                    
                    
                    
                    
                    </div>
                    
                  
                </div>
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ImportantDates;