import React from 'react';
import SEO from '../../common/SEO';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import CaseCallForPaper from '../../component/casestudy/CaseCallForPaper';


const Travel = () => {


    return (
        <>
        <SEO title="Travel to Tunisia" />

        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderTwo />
            {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
             <BreadCrumbOne 
                title="Travel to Tunisia"
                page="Venue"
                />
         <div className="section section-padding expert-counterup-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className='row'>
                    <div className="col-lg-8">
                        <div className="section-heading heading-left">
                           
                        <h4>Direct international flights</h4>
                            <p > Direct flights from abroad are available from the cities in this  <a href='https://www.flightconnections.com/flights-to-tunis-tun' target='_blank'>  link </a>  (subject to change): </p>
                        
                        <h4>Travel to Esprit from the airport</h4>
                        <div className="row">
    
    <iframe  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2862.047543710694!2d10.192812972038938!3d36.9059164383328!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2cb7454c6ed51%3A0x683b3ab5565cd357!2sESPRIT!5e0!3m2!1sen!2stn!4v1680860369273!5m2!1sen!2stn"  width="1200" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
         
  </div>
  <br/>
                       
                        </div>
                    </div>


                    <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>

                    </div>
                <div className='row'>
                <h4>Travel to <a href='https://regencytunishotel.com-tunisia.com/' target='_blank'>Regency Hotel</a> from the airport </h4>
               <div className='col-lg-7'>
              
                        <div className="row">
                      
                        <iframe src="https://www.google.com/maps/d/embed?mid=1FPDjBDjak7Csi8nwiyswtDt02xo&hl=en&ehbc=2E312F" width="640" height="480"></iframe>     
  </div><br/>
                        <h4>Travel to <a href='https://www.goldencarthage.com/en/home/' target='_blank'>Golden Carthage Hotel</a> from the airport </h4>
                        <div className="row">
    
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12761.486468931342!2d10.3124536!3d36.9053783!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2b44eef4323e9%3A0x8658972359bbc8ce!2sGolden%20Carthage!5e0!3m2!1sen!2stn!4v1716986909121!5m2!1sen!2stn" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                         </div>
                         <br/>
                         

               </div>
               <p>Please note that airport transfers to the hotel are not covered by the conference. However, taxi services are readily available directly from the airport. Additionally, the hotel concierge will be happy to assist you in arranging a taxi.</p>
                        
</div>   
              
                </div>
            </div>
        </div>
            
            
           
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Travel;