import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import HeaderTwo from "../common/header/HeaderTwo";

import FooterOne from "../common/footer/FooterOne";
import { useFormik } from "formik";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import {
  Dialog, DialogTitle, DialogContent, DialogActions ,
  FormControl,
  FormControlLabel,
  Alert,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField, 
} from "@mui/material";
import axios from "axios";
import PAYS from "../documents/allcountries";

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  first_name: Yup.string().required("First Name is required").matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed"),
  last_name: Yup.string().required("Last Name is required").matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  nationalite: Yup.string().required("Country is required"),
  numero_tel: Yup.string().required("Telephone Number is required").matches(/^[\d\s]+$/, "Invalid phone number. It must contain only numbers"),
  occupation: Yup.string().required("Occupation is required").matches(/^[a-zA-Z0-9\séèêô'-]*$/, "Special characters are not allowed"),
institution: Yup.string().required("Institution is required").matches(/^[a-zA-Z0-9\séèêô'-]*$/, "Special characters are not allowed"),
has_allergy: Yup.string().required("Please select an option").matches(/^[a-zA-Z0-9\séèêô'-]*$/, "Special characters are not allowed"),
address: Yup.string().required("Address is required").matches(/^[a-zA-Z0-9\séèêô\-,']*$/, "Special characters are not allowed"),
  city : Yup.string().required("City is required").matches(/^[a-zA-Z0-9\séèêô'-]*$/, "Special characters are not allowed"),
  postal: Yup.string().required("Postal code/zip code is required").matches(/^[\w\s]+$/, "Invalid postal code/zip code. It must contain only numbers and letters"),

});
const Registration_Form = () => {
  const targetDate = new Date('2024-05-01');
  const currentDate = new Date();
  const isEarlyRegistrationOpen = currentDate < targetDate;
  const [alertOpen, setAlertOpen] = useState(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [ erreurmsg , setErreurMsg] = useState("");
  const [erreurALergy, setAlergyErreur] = useState("");
  const [erreurOccupation, setErreurOccupation] = useState("");
  const [isSubmitting, setSubmitting] = useState(false); 
  const [telephoneCode, setTelephoneCode] = useState("");
  const [formattedPhoneNumberState, setFormattedPhoneNumberState] = useState("");
  const [apiInfo,setApiInfo]= useState({});
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      nationalite: "",
      numero_tel: "",
      occupation: "",
      institution: "",
      has_allergy: "",
      food_specifications: "",
      customOccupation: "",
      address :"",
      city : "",
      postal : ""
     
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
      
       
        if (formik.isValid) {
          if (values.has_allergy === "Yes" && values.food_specifications === "") {
            setAlergyErreur("Details about the food specification are required ");
            return; 
          } else {
            setAlergyErreur("");
          }
  
          
          if (values.occupation === "Other" && values.customOccupation === "") {
            setErreurOccupation("The Occupation Field is required ");
            return; 
          } else {
            setErreurOccupation("");
          }
          console.log(erreurALergy,erreurOccupation)
          // Ouvrez la boîte de dialogue de confirmation
         const apiKey = '541fe0da7193ce71f0adc48ac92916b54281521beac5a6e715526712';
         const apiUrl = `https://api.ipdata.co/?api-key=${apiKey}`;
          const defaultApiInfo = {
            continent_name: "No_continent_mentionned",
            country_name: "No_country_name_mentionned",
            ip: "No_ip_mentionned",
            region: "No_region_mentionned",
            city: "No_city_mentionned",
          };
          try {
            const res = await axios.get(apiUrl);
            setApiInfo(res.data);
            
          } catch (error) {
            console.error("API Error:", error);
            // Set a default value for apiInfo in case of error
            setApiInfo(defaultApiInfo);
          }
          
          setConfirmationDialogOpen(true);
        } else {
          // La validation du formulaire a échoué, ne faites rien ici
        }
        
      
      } catch (error) {
       
        console.error("API Error:", error);
      }
    },
  });
  const handleRegistrationConfirmation = async () => {
    try {
      setSubmitting(true);
      const formattedPhoneNumber = `+${telephoneCode}${formik.values.numero_tel}`;
   
     
      var Occupation ;
      if(formik.values.occupation === 'Other') {
          Occupation = formik.values.customOccupation
        }
        else {
          Occupation = formik.values.occupation
        }
       
        const participant = {
          first_name: formik.values.first_name,
      last_name: formik.values.last_name,
      email: formik.values.email,
      nationalite: formik.values.nationalite,
      numero_tel: formattedPhoneNumber,
      occupation: Occupation,
      institution: formik.values.institution,
      food_specifications: formik.values.food_specifications,
      event :"CDIO 2024",
      address :formik.values.address,
      city : formik.values.city,
      postal : formik.values.postal,
      additionalInfo: {
        continent_name :apiInfo.continent_name,
        country_name: apiInfo.country_name,
        ip: apiInfo.ip,
        region_name :apiInfo.region,
        city :apiInfo.city,
      },
      amount: 2300000,
        }
      await axios.post(`${process.env.REACT_APP_API_URL}api/auth/signup`, participant);
       setAlertOpen(true);
      formik.resetForm();
      setConfirmationDialogOpen(false);
    } catch (error) {
     
     error.response ?  setErreurMsg(error.response.data.message) : setErreurMsg("Try again later on, we have some issues with the registration");
     
      console.error("API Error:", error);
    } finally {
      // Reset loading state to false regardless of success or failure
      setSubmitting(false);
    }
  };
  const handleCountryChange = (event) => {
    const selectedCountry = PAYS.find(
      (country) => country.name === event.target.value
    );
    const callingCode = selectedCountry ? selectedCountry.callingCodes[0] : "";
    setTelephoneCode(callingCode);
   
    formik.setFieldValue("nationalite", event.target.value, true);
  };
 

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };



 

  return (
    <>
      <SEO title="Registration" />

      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderTwo />
        {/* <BcrumbBannerOne 
                title="ESPRIT"
                paragraph ="School of Engineering"
                styleClass="thumbnail-3"
        
                // mainThumb="/images/cdio/IMG_8177-3.JPG"
            /> */}
        <BreadCrumbOne title="Registration" page="Registration" />
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8">
                <div className="case-study-featured">
                  <div className="section-heading heading-left">
                    
                    <h6 className="title">Conference Online Registration</h6>
                    {isEarlyRegistrationOpen ? (
        <p>Early Registration : Please fill in the form below. </p>
      ) : (
        <p >Regular Registration : : Please fill in the form below. </p>
      )}
                   
      
                  
                    <Box sx={{ width: "100%" }}>
                    

                    <form onSubmit={formik.handleSubmit}>
                    
                            <div>
                              {/* Step 1: Personal Information */}

                              <Stack spacing={1}>
                                {/* <h6 className="title">Personal Information</h6> */}
                               
                                <TextField
                                  label="First Name"
                                  name="first_name"
                                  value={formik.values.first_name}
                                  onChange={formik.handleChange}
                                 
                                />
                                {formik.touched.first_name &&
                                  formik.errors.first_name && (
                                    <span style={{ color: "red" }}>
                                      {formik.errors.first_name}
                                    </span>
                                  )}

                                <TextField
                                  label="Last Name"
                                  name="last_name"
                                  value={formik.values.last_name}
                                  onChange={formik.handleChange}
                                 
                                />
                                 {formik.touched.last_name &&
                                  formik.errors.last_name && (
                                    <span style={{ color: "red" }}>
                                      {formik.errors.last_name}
                                    </span>
                                  )}
                                <TextField
                                  label="Email address"
                                  name="email"
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                 
                                />
                                 {formik.touched.email &&
                                  formik.errors.email && (
                                    <span style={{ color: "red" }}>
                                      {formik.errors.email}
                                    </span>
                                  )}
                                   <TextField
  label="Address"
  name="address"
  value={formik.values.address}
  onChange={formik.handleChange}
/>
{formik.touched.address && formik.errors.address && (
  <span style={{ color: "red" }}>{formik.errors.address}</span>
)}

                                <FormControl
                                  fullWidth
                                
                                >
                                  <InputLabel id="nationalite-label">
                                    Country
                                  </InputLabel>
                                  <Select
                                    labelId="nationalite-label"
                                    id="nationalite"
                                    name="nationalite"
                                    value={formik.values.nationalite}
                                    label="Country"
                                    onChange={handleCountryChange}
                                  >
                                    {PAYS.map((country, index) => (
                                      <MenuItem
                                        key={index}
                                        value={country.name}
                                      >
                                        {country.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {formik.touched.nationalite &&
                                    formik.errors.nationalite && (
                                      <span style={{ color: "red" }}>
                                        {formik.errors.nationalite}
                                      </span>
                                    )}
                                </FormControl>
                             
<TextField
  label="City"
  name="city"
  value={formik.values.city}
  onChange={formik.handleChange}
/>
{formik.touched.city && formik.errors.city && (
  <span style={{ color: "red" }}>{formik.errors.city}</span>
)}

<TextField
  label="Postal Code"
  name="postal"
  value={formik.values.postal}
  onChange={formik.handleChange}
/>
{formik.touched.postal && formik.errors.postal && (
  <span style={{ color: "red" }}>{formik.errors.postal}</span>
)}

                                <TextField
                                  label="Telephone Number"
                                  name="numero_tel"
                                  value={formik.values.numero_tel}
                                  onChange={formik.handleChange}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <strong>+{telephoneCode}</strong>
                                      </InputAdornment>
                                    ),
                                  }}
                                  
                                />
                                 {formik.touched.numero_tel &&
                                  formik.errors.numero_tel && (
                                    <span style={{ color: "red" }}>
                                      {formik.errors.numero_tel}
                                    </span>
                                  )}
                               <FormControl>
                                    <InputLabel id="demo-simple-select-helper-label">
                                      Occupation
                                    </InputLabel>
                                <Select
                                      labelId="demo-simple-select"
                                      value={formik.values.occupation}
                                      label="Occupation"
                                      name="occupation"
                                      onChange={formik.handleChange}
                                     
                                    >
                                      <MenuItem value="" disabled>
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value="Professional">
                                      Professional  
                                      </MenuItem>
                                      <MenuItem value="Academic">
                                      Academic
                                      </MenuItem>
                                      <MenuItem value="Student">
                                        Student
                                      </MenuItem>
                                      <MenuItem value="Other">
                                       Other
                                      </MenuItem>
                                    </Select>
       
                                  {formik.values.occupation === 'Other' && (
    <> <br/> <TextField
    label="Custom Occupation"
    name="customOccupation"
    value={formik.values.customOccupation}
    onChange={formik.handleChange}
    error={formik.touched.customOccupation && Boolean(formik.errors.customOccupation)}
    helperText={formik.touched.customOccupation && formik.errors.customOccupation}
  /> </> 
  )}
    {erreurOccupation && (
                                  <span style={{ color: "red" }}>
                                    {erreurOccupation}
                                  </span>
                                )}
  {formik.touched.occupation && formik.errors.occupation && (
    <span style={{ color: "red" }}>{formik.errors.occupation}</span>
  )}

                                    </FormControl>
                                <TextField
                                  label="Institution"
                                  name="institution"
                                  value={formik.values.institution}
                                  onChange={formik.handleChange}
                                  
                                />
                                 {formik.touched.institution &&
                                  formik.errors.institution && (
                                    <span style={{ color: "red" }}>
                                      {formik.errors.institution}
                                    </span>
                                  )}

                                {/* ... other fields ... */}
                                <Typography variant="h6">
                                
                                    {" "}
                                    Do you have any specific dietary
                                    requirements or allergies ?{" "}
                              
                                </Typography>
                                <RadioGroup
                                  name="has_allergy"
                                  value={formik.values.has_allergy}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.has_allergy &&
                                    formik.errors.has_allergy
                                      ? "true"
                                      : undefined
                                  }
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>

                                {formik.touched.has_allergy &&
                                  formik.errors.has_allergy && (
                                    <span style={{ color: "red" }}>
                                      {formik.errors.has_allergy}
                                    </span>
                                  )}
                                {formik.values.has_allergy === "Yes" && (
                                  <TextField
                                    label="Could you kindly provide details regarding your food specification ?"
                                    name="food_specifications"
                                    value={formik.values.food_specifications}
                                    onChange={formik.handleChange}
                                    
                                  />
                                )}
                                {formik.touched.food_specifications &&
                                  formik.errors.food_specifications && (
                                    <span style={{ color: "red" }}>
                                      {formik.errors.food_specifications}
                                    </span>
                                  )}
                                {erreurALergy && (
                                  <span style={{ color: "red" }}>
                                    {erreurALergy}
                                  </span>
                                )}
                              </Stack>
                              {/* Add similar input fields for lastName and email */}
                            </div>
                            
       <div style={{ textAlign: "right", marginTop :"10px" }}>                
   <Button type="submit" className="axil-btn btn-fill-white" style={{ WebkitTextFillColor: "White"}}> {isSubmitting ? "Submitting Registration..." : "Submit Registration"}</Button>
   </div>   
   <Dialog
  open={isConfirmationDialogOpen}
  onClose={handleConfirmationDialogClose}
  aria-labelledby="confirmation-dialog-title"
  maxWidth="md" // Set the maximum width
  fullWidth // Take up the full width of its container
>
  <DialogTitle id="confirmation-dialog-title" style={{ textAlign: 'center', paddingBottom: '0' }}>
    Confirm Registration
  </DialogTitle>
  <DialogContent>
    <p style={{ textAlign: 'center', paddingBottom: '20px' }}>
      Please confirm that the provided information are correct.
    </p>
    <div>
      <Typography variant="subtitle1"><strong>Entered Information:</strong></Typography>
    {isEarlyRegistrationOpen ? (
        <p> <strong> Early Registration : 2100,00 TND</strong> </p>
      ) : (
        <p > <strong>Regular Registration : : 2300,00 TND</strong>  </p>
      )}
      <ul>

        <li>
          <strong>First Name:</strong> {formik.values.first_name}
        </li>
        <li>
          <strong>Last Name:</strong> {formik.values.last_name}
        </li>
        <li>
          <strong>Email:</strong> {formik.values.email}
        </li>
        <li>
          <strong>Address:</strong> {formik.values.address}
        </li>
        <li>
          <strong>Country:</strong> {formik.values.nationalite}
        </li>
        <li>
          <strong>City:</strong> {formik.values.city}
        </li>
        <li>
          <strong>Postal code:</strong> {formik.values.postal}
        </li>
        <li>
          <strong>Telephone Number:</strong> + {telephoneCode} {formik.values.numero_tel}
        </li>
        <li>
          <strong>Occupation:</strong> {formik.values.occupation ==="Other" ? formik.values.customOccupation : formik.values.occupation}
        </li>
        <li>
          <strong>Institution:</strong> {formik.values.institution}
        </li>
        <li>
          <strong>Dietary requirements:</strong> {formik.values.has_allergy==="Yes" ? formik.values.food_specifications : "Not mentionned"}
        </li>
        {/* Add other fields as needed */}
      </ul>
    </div>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'center', padding: '10px 24px' }}>
    <Button onClick={handleConfirmationDialogClose}  color="success" variant="outlined">
      Cancel
    </Button>
    <Button  disabled={isSubmitting}  onClick={handleRegistrationConfirmation} className="axil-btn btn-fill-white" style={{ WebkitTextFillColor: "White"}} >
       {isSubmitting ? "Submitting Registration..." : "Confirm Registration"}
    </Button>
    <br></br>
   
    
  </DialogActions>
  <span  style={{ color: 'red', fontSize: '10px', fontWeight: 'bold' , marginLeft : "70px" , marginBottom :"10px"}} >
        {erreurmsg}
        </span>
</Dialog>

</form>


        <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">Registration Successful</DialogTitle>
        <DialogContent>
          <Typography>
            A confirmation email will be sent to your registered address.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} className="axil-btn btn-fill-white" style={{ WebkitTextFillColor: "White"}} >
            Close
          </Button>
        </DialogActions>
      </Dialog>
                    </Box>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3">
  <div className="contact-form-box shadow-box">
    <h5>Organisers: </h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/esprit.png`}
      alt="esprit logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo cdio.png`}
      alt="cdio logo"
      style={{
        display: "block",
        margin: "0 auto", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/logo chaire unesco.png`}
      alt="unesco logo"
      style={{
        display: "block",
        margin: "20px auto 0", // Adding space below the logo
        maxWidth: "80%",
        height: "auto",
      }}
    />
    <h5>Sponsors:</h5>
    <img
      src={`${process.env.PUBLIC_URL}/images/cdio/devoteam-logo.png`}
      alt="devoteam logo"
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "80%",
        height: "auto",
      }}
    /><img
    src={`${process.env.PUBLIC_URL}/images/logo_stb.png`}
    alt="stb logo"
    style={{
      display: "block",
      margin: "20px auto 0",
      maxWidth: "80%",
      height: "auto",
    }}
  />
  </div>
</div>
            </div>
          </div>
        </div>

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Registration_Form;
